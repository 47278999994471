import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './prelogin/login/login.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ForgotPasswordComponent } from './prelogin/forgot-password/forgot-password.component';
import { SignUpComponent } from './prelogin/sign-up/sign-up.component';
import { VerifyOTPComponent } from './prelogin/verify-otp/verify-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { HeaderComponent } from './includes/header/header.component';
import { BookingComponent } from './postlogin/booking/booking.component';
import { AgmCoreModule } from '@agm/core';
import { LocationComponent } from './postlogin/location/location.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  NgxUiLoaderRouterModule
} from 'ngx-ui-loader';
import { PhoneNumberComponent } from './prelogin/phone-number/phone-number.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CalendarComponent } from './postlogin/calendar/calendar.component';
import { ShowCoachesComponent } from './postlogin/show-coaches/show-coaches.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { CoachProfileComponent } from './postlogin/coach-profile/coach-profile.component';
import { DateAgoPipe } from './shared/date-ago.pipe';
import { BookingSummaryComponent } from './postlogin/booking-summary/booking-summary.component';
import { LogoutComponent } from './postlogin/logout/logout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './postlogin/profile/profile.component';
import { SessionSuccessComponent } from './postlogin/session-success/session-success.component';
import { EditProfileComponent } from './postlogin/edit-profile/edit-profile.component';
import { YourLocationsComponent } from './postlogin/your-locations/your-locations.component';
import { YourPaymentsComponent } from './postlogin/your-payments/your-payments.component';
import { FaqComponent } from './postlogin/faq/faq.component';
import { CoachPackageComponent } from './postlogin/coach-package/coach-package.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PaymentPageComponent } from './postlogin/payment-page/payment-page.component';
import { NgKnifeModule } from 'ng-knife';
import { DragScrollModule } from 'ngx-drag-scroll';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { BookPackageFromProfileComponent } from './postlogin/book-package-from-profile/book-package-from-profile.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#ffc600",
  bgsType: SPINNER.threeStrings,
  fgsType :  SPINNER.threeStrings,
  fgsColor: "#ffc600",
  pbColor:"#ffc600"
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    VerifyOTPComponent,
    HeaderComponent,
    BookingComponent,
    LocationComponent,
    PhoneNumberComponent,
    CalendarComponent,
    ShowCoachesComponent,
    CoachProfileComponent,
    DateAgoPipe,
    BookingSummaryComponent,
    LogoutComponent,
    ProfileComponent,
    SessionSuccessComponent,
    EditProfileComponent,
    YourLocationsComponent,
    YourPaymentsComponent,
    FaqComponent,
    CoachPackageComponent,
    PaymentPageComponent,
    BookPackageFromProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgOtpInputModule,
    FormsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB4zi5ll91X-Fldxb33BX09CqYPMC9JGME'
    }),
    SocialLoginModule,
    NgxWebstorageModule.forRoot(),
    GooglePlaceModule,
    BrowserAnimationsModule,
    NgbModule,
    ImageCropperModule,
    NgxSkeletonLoaderModule,
    NgKnifeModule,
    DragScrollModule
  ],
  providers: [
    DatePipe,
    { provide: LocationStrategy,
      useClass: PathLocationStrategy
    } ,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('207429081006867')
          }
        ]
      } as SocialAuthServiceConfig,
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }
