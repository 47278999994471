<app-header></app-header>
<div class="fullBGLogin">
  <div class="halfBG" [style.background-image]="'url('+postLoginService.bookingData.workoutImageURL+')'" ></div>
  <div class="container text-center">
    <h1 class="headMargin"></h1>
    <div *ngIf="errorMessage!=''" class="alert alert-danger ">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success ">
      {{successMessage}}
    </div>

    <div *ngIf="coach!=undefined" class="row  no-gutters  mainBox">
      <div class="col-md-6">
        <div class="profileLeft">
          <div class="profileInfo row">
            <div class="col-3">
              <img [src]="coach.full_profile_pic_url">
            </div>
            <div class="col-9">
              <h3>{{coach.first_name}}</h3>
              <div [class.scrollbar]="show" class=" ht-100" id="style-9">
                <div class="force-overflow">
                  <p>
                    {{coach.description.substring(0, 90)}}
                    <span id="more" [class.d-none]="!show">
                      {{coach.description.substring( 90)}}
                    </span>
                    <span class="dots" (click)="show = !show">{{ show ? 'Show less': '...Show More' }} </span>

                  </p>
                </div>
              </div>
              <!-- <div class="">
                      <ul class="profileTag">
                        <li>Balanced</li>
                        <li>Relaxed</li>
                      </ul>
                    </div> -->
            </div>
          </div>
          <div class="divider"></div>
          <!-- <div class="row">
                  <div class="col-3">
                    <div class="offer">You save 50%</div>
                  </div>
                </div> -->
          <div class="profileOverview no-gutters row">
            <div class="col-3">
              <div class="pricing row no-gutters">
                <div class="col-12 price"><span>RM</span> {{coachPrice?.session_price}}</div>
                <!-- <div class="col-4"><span class="strikeThrough">200</span></div> -->
              </div>
              <div class="overviewType">Per session</div>
            </div>
            <div class="col-3">
              <div class="overviewText">{{coach.liked_percentage}}%</div>
              <div class="overviewType">Rating</div>
            </div>
            <div class="col-3">
              <div class="overviewText">{{coach.completed_training_sessions_total}}</div>
              <div class="overviewType">Sessions</div>
            </div>
            <div class="col-3">
              <div class="overviewText">{{coach.years_of_experience}} Years</div>
              <div class="overviewType">Experience</div>
            </div>
          </div>
          <div *ngIf="coach.five_latest_reviews.length>0" class="profileRatings">
            <ngb-carousel [pauseOnHover]="true" [pauseOnFocus]="true" >
              <ng-template ngbSlide *ngFor="let item of coach.five_latest_reviews" >
                <div  class="scrollbar" style="height: 110px;" id="style-9">
                  <div class="force-overflow">
                    <p class="carouselText">{{item.comment}}</p>
                    </div>
                </div>
                <div class="carouselProfile">
                  <div class="pname">
                    {{item.user.first_name}} {{item.user.last_name}}
                    <span>{{item.created_at | date}}</span>
                  </div>
                </div>
              </ng-template>
            </ngb-carousel>

          </div>
          <div class="achievements">
            <button class="btn btn-themeBG btn-block" data-toggle="collapse" href="#collapseExample" role="button"
              aria-expanded="false" aria-controls="collapseExample"><i class="fa fa-graduation-cap"></i>Cert and
              Achievements <i class="fa fa-caret-down"> </i></button>
            <div class="collapse mg-t-5" id="collapseExample">
              <h5 class="mg-b-5">CERTIFICATIONS</h5>
              <ul class="pd-l-10 mg-0 mg-t-20 tx-13">
                <li *ngFor="let certification of coach.certifications">{{certification}}</li>
              </ul>
              <h5 class="mg-b-5">ACHIEVEMENTS</h5>
              <ul class="pd-l-10 mg-0 mg-t-20 tx-13">
                <li *ngFor="let achievement of coach.achievements">{{achievement}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="profileRight text-left">
          <div class="col-md-12 pd-t-20 headFont">
            Select trainer’s availability
          </div>
          <div class="col-md-12">
            <div  class="scrollbar" id="style-9" style="height:150px">
              <div class="force-overflow">
                <ul class="trainersAvailability">
                  <li *ngFor="let slot of formattedSlots;let i=index">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="customRadio1{{i}}" [(ngModel)]="preferredSlot"  name="customRadio" [value]="slot" class="custom-control-input" >
                      <label class="custom-control-label" for="customRadio1{{i}}">{{slot}}</label>
                    </div>
                  </li>
                </ul>
            </div>
            </div>
          </div>
          <div class="col-md-12 pd-t-20 subHead">
            Would you like to adjust your session details?
          </div>
          <div class="col-12">
            <div class="sessionBox row">
              <div class="col-7 col-md-8">
                <p class="head">Number of Participants</p>
              </div>
              <div class="col-1">
                {{noParticipants}}
              </div>
              <div class="col-3 counterHead">
                <button [disabled]="noParticipants==1"  class="btn btn-light btn-icon circleBttn" (click)="decrement('participants')" ><i class="fa fa-minus"></i></button>
                <button  [disabled]="noParticipants>=5" class="btn btn-light btn-icon circleBttn" (click)="increment('participants')" ><i class="fa fa-plus"></i></button>
              </div>
            </div>
            <div class="sessionBox row">
              <div class="col-7 col-md-8">
                <p class="head">Number of Sessions</p>
              </div>
              <div class="col-1">
                {{noSessions}}
              </div>
              <div class="col-3 counterHead">
                <button [disabled]="noSessions==1" class="btn btn-light btn-icon circleBttn" (click)="decrement('session')" ><i class="fa fa-minus"></i></button>
                <button [disabled]="noSessions>=10" class="btn btn-light btn-icon circleBttn" (click)="increment('session')" ><i class="fa fa-plus"></i></button>
              </div>
            </div>
            <div class="mg-t-20">
              <button (click)="next()" type="button" class="btn btn-yellow btn-block">NEXT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
