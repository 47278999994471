<app-header></app-header>
<div class="fullBGLogin">
  <div class="topWidth">
  </div>
  <div class="container">
    <div *ngIf="errorMessage!=''" class="alert alert-danger ">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success ">
        {{successMessage}}
      </div>

    <h1 class="headingH1">Your Payments</h1>
    <div class="mainBox row">
      <div class="col-12 col-md-7 leftSide" >
        <h3><a class="whiteLink mg-r-10" routerLink="/profile"><i class="fa fa-arrow-left"></i></a> Payment Methods</h3>
        <div  class=" locationDiv"  *ngIf="walletCredits!=''">
          <div  class="locs">
            <div class="loc row" *ngFor="let wallet of walletCredits">
              <div class="viewL">
                <p class="head">
                  <img *ngIf="wallet.image_url==null" src="../../../assets/img/walletIcon.png">
                  <img *ngIf="wallet.image_url!=null" [src]="wallet.image_url">
                  {{wallet.description}}</p>
              </div>
              <div class="viewR">
                <div *ngIf="wallet.image_url!=null" (click)="deletePaymentMethod(wallet.token)" class="yellowLink" href=""><i class="fa fa-trash"></i></div>
               </div>
            </div>
          </div>
        </div>
        <div *ngIf="walletCredits==''">
         No Payment methods
        </div>
      </div>
    </div>
  </div>
</div>
