import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';

@Component({
  selector: 'app-book-package-from-profile',
  templateUrl: './book-package-from-profile.component.html',
  styleUrls: ['./book-package-from-profile.component.css']
})
export class BookPackageFromProfileComponent implements OnInit {

  errorMessage='';
  successMessage='';
  sessionLength=60;

  coachId ;
  coachProfile;
  selectedCategory="";
  noSessions=10;
  noParticipants=1;
  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute) {
      this.authGuard.isTokenValid();
      this.activatedRoute.params.subscribe(params => {
        if (this.activatedRoute.snapshot.params['id']) {
          this.coachId = params['id'];
          this.ngxService.start();
          this.postLoginService.getTrainerProfile(this.coachId).subscribe(
            res=>{
              this.coachProfile=res['trainer'];
              this.ngxService.stop();
            },
            err=>{
              this.ngxService.stop();
              this.errorMessage='Error in service!';
              setTimeout(() => {
                this.errorMessage='';
              }, 3000);
            }
          )
        }else{
          this.router.navigate(['booking']);
        }
     });
     }

  ngOnInit(): void {}



  incrementSession() {
    this.sessionLength += 15;
  }

  decrementSession() {
    this.sessionLength -= 15;
  }
  increment(type){
    if(type=="session"){
        if(this.noSessions==1){
          this.noSessions=5;
        }
        else{
          this.noSessions+=5;
        }
    }else{
      this.noParticipants+=1;
    }
  }

  decrement(type){
    if(type=="session"){
        if(this.noSessions==5){
          this.noSessions=1;
        }
        else{
          this.noSessions-=5;
        }
    }else{
        this.noParticipants-=1;
    }
  }

  next(){
    this.postLoginService.bookingData.flow="bookPackageForCoach";
    this.postLoginService.bookingData.trainerId=this.coachId;
    this.postLoginService.bookingData.noParticipants=Number(this.noParticipants);
    this.postLoginService.bookingData.trainerName=this.coachProfile.first_name;
    this.postLoginService.bookingData.noSessions=Number(this.noSessions);
    this.postLoginService.bookingData.sessionLength=this.sessionLength;
    this.postLoginService.bookingData.subCategoryId=this.selectedCategory;
    this.ngxService.start();
    this.postLoginService.getTrainerPrice(this.postLoginService.bookingData.trainerId,this.postLoginService.bookingData.sessionLength,
      this.postLoginService.bookingData.noSessions, this.postLoginService.bookingData.noParticipants).subscribe(
      res=>{
        var coachFees=res['price_quote'];
        this.postLoginService.bookingData.coachFees=Number(coachFees.session_price);
        this.ngxService.stop();
        this.router.navigate(['bookingSummary']);
      });
  }


}
