<div class="fullBGLogin newBG">
  <img src="../../../assets/img/logo.png" class="joompLogo">
  <div class="container">
    <div class="row">
      <div class="col-md-6 loginPageBG">
        <div class="col-md-12 loginLeftSide pd-t-100">
          <img src="../../../assets/img/group-7.svg" class="Group-7">
          <h3>Quality</h3>
          <p>The city’s top freelancers, on the country’s leading platform, all assessed by our internal quality
            assurance processes. </p>
        </div>
        <div class="col-md-12 loginLeftSide pd-t-30">
          <img src="../../../assets/img/group-8.svg" class="Group-7">
          <h3>Convenience</h3>
          <p>From ease of booking to customised coaching, every step is focused on you. </p>
        </div>
        <div class="col-md-12 loginLeftSide pd-t-30">
          <img src="../../../assets/img/group-9.svg" class="Group-7">
          <h3>Guarantee</h3>
          <p>Our professionals have been delivering results for customers for years. Read verified reviews from real
            customers before you proceed with one of our top performers. </p>
        </div>
      </div>
      <div class="col-md-6 pd-t-50 rightSideLoginComp">
        <p class="Welcome-back-log-in">Create an account</p>
        <button   (click)="signInWithFB()"  class="btn btn-primary btn-block">
          <i class="fa fa-facebook-square pd-r-20"></i>Continue with facebook
        </button>
        <div class="divider-text">or Register via</div>
        <form class="mg-t-30" id="signUpForm" #signUpForm="ngForm" (ngSubmit)="signUp(signUpForm)">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text"
                  [ngClass]="{ 'is-invalid': firstName.invalid && (firstName.dirty || firstName.touched)}"
                  #firstName="ngModel" [(ngModel)]="preloginService.loggedUser.firstName" pattern="[a-zA-z ].+"
                  name="first_name" class="form-control form-control-lg text-center darkInputBox"
                  placeholder="Enter your first name" required>
                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="errorDiv">
                  <div *ngIf="firstName.errors.required">
                    First name is required
                  </div>
                  <div *ngIf="firstName.errors?.pattern">
                    Enter valid first name
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngClass]="{ 'is-invalid': lastName.invalid && (lastName.dirty || lastName.touched)}"
                  #lastName="ngModel" [(ngModel)]="preloginService.loggedUser.lastName" pattern="[a-zA-z ].+"
                  name="last_name" class="form-control form-control-lg text-center darkInputBox"
                  placeholder="Enter your last name" required>
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="errorDiv">
                  <div *ngIf="lastName.errors.required">
                    Last name is required
                  </div>
                  <div *ngIf="lastName.errors?.pattern">
                    Enter valid last name
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <select class="custom-select darkInputBox"  #gender="ngModel" [(ngModel)]="preloginService.loggedUser.gender" required name="gender">
                  <option value='' disabled>Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched)}"
                  #email="ngModel" [(ngModel)]="preloginService.loggedUser.email" pattern="\S+@\S+\.\S+" name="email"
                  class="form-control form-control-lg text-center darkInputBox" placeholder="Enter your email" required>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="errorDiv">
                  <div *ngIf="email.errors.required">
                    Email is required.
                  </div>
                  <div *ngIf="email.errors?.pattern">
                    Enter valid email address
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="input-group mg-b-10">
                  <div class="input-group-prepend">
                    <span class="input-group-text darkInputBox" id="basic-addon1">+60</span>
                  </div>
                  <input type="text" class="form-control darkInputBox" placeholder="Phone number"
                    aria-label="Phone number">
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="password"
                  [ngClass]="{ 'is-invalid': password.invalid && (password.dirty || password.touched)}"
                  #password="ngModel" [(ngModel)]="preloginService.loggedUser.password" name="password"
                  class="form-control form-control-lg text-center darkInputBox" placeholder="Enter a password" required>
                <div *ngIf="password.invalid && (password.dirty || password.touched)" class="errorDiv">
                  <div *ngIf="password.errors.required">
                    Password is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">

              <div class="form-group">
                <input type="password"
                  [ngClass]="{ 'is-invalid': password1.invalid && (password1.dirty || password1.touched)}"
                  #password1="ngModel" [(ngModel)]="preloginService.loggedUser.password1" name="password_confirmation"
                  class="form-control form-control-lg text-center darkInputBox" placeholder="Repeat password" required>
                <div *ngIf="password1.invalid && (password1.dirty || password1.touched)" class="errorDiv">
                  <div *ngIf="password1.errors.required">
                    Password is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 tnc">
              <p>By creating an account you’re agreeing to our <span class="textColorThemeYellow">terms and
                  conditions</span> </p>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <button [disabled]="!signUpForm.valid" type="submit"
                  class="btn btn-yellow float-right d-block">REGISTER</button>

              </div>
            </div>
          </div>
        </form>
        <div *ngIf="errorMessage!=''" class="alert alert-danger errorDiv" [innerHTML]="errorMessage.replace('\n','<br>')">
       {{errorMessage}}
        </div>
        <div *ngIf="successMessage!=''" class="alert alert-success errorDiv">
          {{successMessage}}
        </div>

        <div class="divider"></div>
        <a routerLink="/login" type="button" class="btn darkButton btn-block">Already have an account? <span
            class="tx-semibold textColorThemeYellow">Log in</span></a>
      </div>
    </div>
  </div>

</div>
