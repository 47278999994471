<app-header></app-header>
<div class="fullBGLogin">
  <div class="container text-center">
    <h1 class="headMargin"></h1>
    <div  class="successBox">
      <div class="yellowCircle">
        <i class="fa fa-check"></i>
      </div>
      <h3>Congratulations!</h3>
      <p>Your booking has been completed & we’ve
        logged your upcoming session in your
        Sessions calendar.</p>
      <div class="col-12">
        <a href="/profile" class="btn btn-yellow tx-black">View Session Details</a>
      </div>
      <a href="/booking" >Back Home</a>
    </div>
  </div>
</div>
