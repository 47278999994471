import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PreloginServiceService } from 'src/app/shared/prelogin-service.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  successMessage ='';
  errorMessage ='';
  constructor(public router:Router,
    public preloginService:PreloginServiceService,
    private ngxService: NgxUiLoaderService,
    private authService: SocialAuthService,
    private storage:LocalStorageService) { }

  ngOnInit() {
  }
fbLoginData = {
  first_name:null,
  last_name : null,
  email:null,
  access_token:null,
  uid:null,
  provider:null
};
  signInWithFB(): void {
    this.ngxService.start();
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      x => {
        this.fbLoginData.access_token = x.authToken;
        this.fbLoginData.uid = x.id;
        this.fbLoginData.provider = x.provider;
        this.preloginService.omniauth(this.fbLoginData).subscribe(
          res=>{
            if(res['user'].verified==false){
              this.preloginService.loggedUser.auth_token=res['user'].auth_token;
              this.router.navigate(['phoneNumber']);
              this.ngxService.stop();
            }else{
              this.storage.store('auth_token', res['user'].auth_token);
              var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
              this.storage.store('userData',data);
              this.router.navigate(['booking']);
              this.ngxService.stop();
            }
          },
          err=>{
            this.errorMessage = err.error.error;
            this.ngxService.stop();
          }
        );
      })
    }

  signUp(form:NgForm){
    this.ngxService.start();
    this.preloginService.signUpUser(form.value).subscribe(
      res=>{
        if(res['user'].verified==false){
          this.preloginService.loggedUser.auth_token=res['user'].auth_token;
          this.router.navigate(['phoneNumber']);
        }else{
          this.storage.store('auth_token', res['user'].auth_token);
          var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
          this.storage.store('userData',data);
          this.router.navigate(['booking']);
        }
        this.ngxService.stop();
      },
      err=>{
        this.errorMessage = err.error.error;
        this.ngxService.stop();
      }
    )
  }
}
