import { AgmInfoWindow } from '@agm/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { LatLngLiteral } from 'ngx-google-places-autocomplete/objects/latLng';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  locations;
  result = {
    id:'',
    latitude:3.056733,
    longitude:101.585119,
    short_address:'Subang Jaya',
    full_address:'',
    area:{
      id:'',
      name:''
    }
  }
  selectedAddress='';
  currentIW: AgmInfoWindow;
  previousIW: AgmInfoWindow;
  addNewAddress='';
  options={
    };
    errorMessage='';successMessage='';

  paths=[];
  user_location;
  newPlaceAddress='';
  gymLocations;
  selectedGym=[];
  enableSaveLoc=false;
  locationType="UserLocation";
  selectedGymId='';
  noDates=false;
  existingBookingError='';
  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute) {
          this.authGuard.isTokenValid();
          this.currentIW = null;
          this.previousIW = null;
          this.activatedRoute.queryParams.subscribe(() => {
            if (this.router.getCurrentNavigation().extras) {
              if (this.router.getCurrentNavigation().extras.state) {
                if (this.router.getCurrentNavigation().extras.state.existingBookingError) {
                  this.existingBookingError  = this.router.getCurrentNavigation().extras.state.existingBookingError;
                }
                if (this.router.getCurrentNavigation().extras.state.noDates) {
                  this.noDates  = this.router.getCurrentNavigation().extras.state.noDates;
                }
              }
            }
          });
          setTimeout(() => {
            this.noDates=false;
            this.existingBookingError='';
          }, 5000);
     }


  ngOnInit(): void {
    if(this.postLoginService.bookingData.mainCategoryId==''){
      this.router.navigate(['booking']);
    }
    this.ngxService.start();
    this.postLoginService.getAreaCoordinates().subscribe(
      res=>{
        res.forEach(area => {
          var latLngArray=[]
          area.polygon_coordinates.forEach(path => {
            var latLng = {"lat":path.coordinate[0],"lng":path.coordinate[1]}
            latLngArray.push(latLng);
          });
          this.paths.push({"id":String(area.id),"cords":latLngArray});
        });
        this.postLoginService.getUserLocations().subscribe(
          res=>{
            this.locations=res;
            if(this.locations.length!=0){
              this.result=res[0];
              this.selectedAddress=this.result.id;
            }
            this.postLoginService.getGymLocations().subscribe(
              res=>{
                this.gymLocations=res;
                this.selectedGym=  Array(this.gymLocations.length).fill(false);
                this.ngxService.stop();
              }
            );
          }
        );
      }
    );

  }
  onSelectChange(value){
    if(value!="addNew"){
    this.selectedGymId="";
      this.addNewAddress="";
      this.locationType="UserLocation"
        this.result = this.locations.find(item => {
          return item.id == value;
      });
      if (this.previousIW) {
        this.previousIW.close();
      }
    }
    else{
      this.addNewAddress="Yes";
    }
  }

  mapClick() {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  getShortAddForID(id){
    if(id!="addNew"){
    return this.locations.find(item => {
      return item.id == id;
      }).short_address;
    }
  }
  markerClick(infoWindow,id) {
          this.result = this.locations.find(item => {
            return item.id == id;
        });
        this.selectedAddress=this.result.id;

        if (this.previousIW) {
          this.currentIW = infoWindow;
          this.previousIW.close();
        }
        this.previousIW = infoWindow;
    }


  checkGeoFence(lat,lng){
    var isInside=null;
    this.paths.forEach(path => {
      if(isInside==null){
        var pathPolygon = new google.maps.Polygon({ paths: path.cords });
        var curPosition = new google.maps.LatLng(lat,lng);
        const resultColor = google.maps.geometry.poly.containsLocation(
          curPosition,
          pathPolygon
        )
          ? path.id
          : null;
        if(resultColor!=null){
          isInside=resultColor;
        }
      }
    });
    return isInside;
  }
  saveNewLocation(){
    this.ngxService.start();
    this.postLoginService.userLocationCreate(this.user_location).subscribe(
      res=>{
        this.locations.pop();
        this.locations.push(res['user_location'])
        this.selectedAddress=res['user_location'].id;
        this.result.area.id=res["user_location"].area.id
        this.result.id=res["user_location"].id
        this.result.area.name=res['user_location'].area.name
        this.ngxService.stop();
        this.addNewAddress='';
        this.enableSaveLoc=false;
        this.successMessage="New address saved successfully!";
        setTimeout(() => {
          this.successMessage='';
        }, 3000);
      },
      err=>{
        this.errorMessage = err.error.error;
        this.ngxService.stop();
        setTimeout(() => {
          this.errorMessage='';
        }, 3000);
      }
    );

  }

  public handleAddressChange(address: Address) {
    this.enableSaveLoc=false;
      this.user_location = {
        id:address.place_id,
        area_id:address.place_id,
        latitude:Number(address.geometry.location.lat()),
        longitude:Number(address.geometry.location.lng()),
        short_address:address.name,
        full_address:address.formatted_address,
        area:{
          id:'',
          name:''
        }
      }
      var areaId = this.checkGeoFence(this.user_location.latitude,this.user_location.longitude);
      if(areaId!=null)
      {
        this.enableSaveLoc=true;
        this.user_location.area_id=areaId;
        this.result = this.user_location;
        this.locations.push(this.user_location)
      }
      else{
          this.errorMessage="We are not serving this location. Make sure the location falls under the marked area";
          setTimeout(() => {
            this.newPlaceAddress='';
            this.errorMessage='';
          }, 3000);
      }
  }

  clear(){
    this.locations.pop();
    this.enableSaveLoc=false;
    this.newPlaceAddress='';
  }

  next(){
    this.postLoginService.bookingData.addressLocationId=this.result.id;
    this.postLoginService.bookingData.addressAreaId=String(this.result.area.id);
    this.postLoginService.bookingData.short_address=this.result.short_address;
    this.postLoginService.bookingData.locationType=this.locationType;
    this.postLoginService.bookingData.areaName=this.result.area.name;
    this.router.navigate(['calendar']);
  }

  selectGymLocation(index,id){
    this.result = this.gymLocations.find(item => {
      return item.id == id;
    });
    this.selectedAddress=this.result.id;
    this.selectedGym=  Array(this.gymLocations.length).fill(false);
    this.selectedGym[index]=true;
    this.selectedGymId="yes";
    this.locationType="GymLocation";
    this.result.id=this.gymLocations[index].id;
    this.result.area.id=this.gymLocations[index].area.id;
    this.result.short_address=this.gymLocations[index].short_address;
    this.result.area.name=this.gymLocations[index].area.name;
  }
}
