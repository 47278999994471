<app-header></app-header>
<div class="fullBGLogin">
  <div class="halfBG" [style.background-image]="'url('+postLoginService.bookingData.workoutImageURL+')'" ></div>
    <div class="container text-center">
        <h1 class="bookingHeading">{{this.postLoginService.bookingData.workoutName}}</h1>
        <div *ngIf="errorMessage!=''" class="alert alert-danger ">
            {{errorMessage}}
          </div>
          <div *ngIf="successMessage!=''" class="alert alert-success ">
              {{successMessage}}
            </div>

        <div class="row  no-gutters  mainBox">
            <div class="col-md-6 pd-t-20 borderRight headFont">
               Booking Overview
            </div>
            <div class="col-md-6 pd-t-20  headFont">
              {{coaches?.length}} Available Coaches Found
            </div>
                <div class="col-md-6 pd-t-20  borderRight ht-400 ">
                    <div class="inpBox row">
                        <div class="col-12 ">
                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">Location</label>
                            <u routerLink="/location">Change</u>
                        </div>
                        <div class="col-12 ">
                          <p class="mg-b-0">{{this.postLoginService.bookingData.short_address}}</p>
                        </div>
                    </div>
                    <div class="inpBox row">
                        <div class="col-12">
                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">Date</label>
                            <u routerLink="/calendar">Change</u>
                          </div>
                        <div class="col-12">
                          <p class="mg-b-0">{{this.postLoginService.bookingData.selectedDate}}</p>
                        </div>
                    </div>
                    <div class="inpBox row">
                        <div class="col-12 ">
                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">Time</label>
                            <u routerLink="/calendar">Change</u>
                        </div>
                        <div class="col-12">
                          <p class="mg-b-0">{{this.postLoginService.bookingData.selectedTime}}</p>
                        </div>
                    </div>
                    <div class="infoBox">
                      <p class="head">
                        Not happy with the results?
                      </p>
                      <p class="nrml">
                        Not sure which of the above coaches is most suitable for you? Whatsapp us <a href="https://wa.me/60126955667?text=Hey%20joompa%2C%20I%E2%80%99m%20not%20sure%20which%20coach%20to%20choose%21%20Please%20help%20me%20work%20out%20which%20coach%20suits%20my%20goal%20%3A%29" target="_blank" class="yellowLink">here</a> </p>
                    </div>
                </div>
                <div class="col-md-6 pd-t-20">
                    <div class="mapBox row">
                        <div  *ngFor="let coach of coaches" class="trainerDiv">
                          <div class="imageBox">
                            <a *ngIf="coach.full_video_url!=null" (click)="open(coach.full_video_url)">
                              <img [src]="coach['full_profile_pic_url']" class="videoBorder">
                              <div class="overlay">
                                <div class="text"><i class="fa fa-play"></i></div>
                              </div>
                            </a>
                          </div>

                          <img  *ngIf="coach.full_video_url==null" [src]="coach['full_profile_pic_url']">

                          <p class="head">{{coach.first_name}}</p>
                          <p class="workouts">{{coach.years_of_experience}} years • {{coach.completed_training_sessions_total}} Sessions</p>
                          <p class="workouts">RM {{coach.price_quote.session_price}}</p>
                          <p class="workouts">per session </p>
                          <div class="divider"></div>
                          <button  (click)="next(coach.id)" class="btn btn-yellow">Select</button>
                        </div>
                    </div>

                </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content darkModal">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Coach Video</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class='Flexible-container'>
          <video id="video" [attr.src]="videoUrl" width="165" style="height:100%; padding:0;" class="embed-responsive embed-responsive-16by9" id="myVideo" controls autoplay></video>
        </div>
      </div>
      <div class="modal-footer">

      </div>
    </div>
  </div>
</div>
