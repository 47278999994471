import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment'
import { HttpClient,HttpHeaders } from '@angular/common/http'
import { LoginUser } from './common.model'
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class PreloginServiceService {


  loggedUser : LoginUser = {
    email : '',
    password : '',
    type:'',
    password1:'',
    mobile:'',
    otp:null,
    oldPassword:'',
    firstName:'',
    lastName:'',
    gender:'',
    countryCode:'',
    auth_token:'',
    coach_code: '',
    first_name: '',
    full_profile_pic_url: '',
    id: 0,
    last_name: '',
    medical_conditions: '',
    most_recent_trainers: '',
    notes: '',
    phone_area_code: '',
    phone_number: '',
    terms_accepted: '',
    total_trainers: '',
    verified: '',
    wallet_value: ''
  };

  constructor(private http : HttpClient,private router: Router,private storage:LocalStorageService) { }


  loginUser(user : LoginUser){
    return this.http.post(environment.BaseURL+"/sessions/",{"session":user});
  }

  signUpUser(user : LoginUser){
    return this.http.post(environment.BaseURL+"/registrations/",{"registration":user});
  }

  requestOTP(phone_number){
    var headers = this.getHeaders();
    return this.http.post(environment.BaseURL+"/phone_authentications/",{"phone_authentications":{"phone_number":phone_number.phone_number,"phone_area_code":phone_number.country_code}},headers);
  }

  verifyOTP(phOb){
    var headers = this.getHeaders();
    return this.http.post(environment.BaseURL+"/phone_authentications/verify/",{"phone_authentications":{"phone_number":phOb.phone_number,"phone_area_code":phOb.country_code,"pin":phOb.code}},headers);
  }

  omniauth(omniauth){
    return this.http.post(environment.BaseURL+"/omniauth/",{omniauth});
  }


  getProfile(){
    var headers = this.getHeaders();
    return this.http.get(environment.BaseURL+"/users/profile/",headers);
  }

  getHeaders(){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', this.loggedUser.auth_token)
    }
    return header;
  }
}
