<div class="fullBGLogin newBG">
  <img src="../../../assets/img/logo.png" class="joompLogo">
    <div class="container">
        <div class="row">
            <div class="col-md-6 loginPageBG">
                <div class="col-md-12 loginLeftSide pd-t-100">
                    <img src="../../../assets/img/group-7.svg" class="Group-7">
                    <h3>Quality</h3>
                    <p>The city’s top freelancers, on the country’s leading platform, all assessed by our internal quality assurance processes. </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-8.svg" class="Group-7">
                    <h3>Convenience</h3>
                    <p>From ease of booking to customised coaching, every step is focused on you.  </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-9.svg" class="Group-7">
                    <h3>Guarantee</h3>
                    <p>Our professionals have been delivering results for customers for years. Read verified reviews from real customers before you proceed with one of our top performers. </p>
                </div>
            </div>
            <div class="col-md-6 pd-t-100">
                <p class="Welcome-back-log-in">Reset Your Password</p>
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text" class="form-control darkInputBox" placeholder="Email or phone number">
                    </div>
                    <div class="clearfix"></div>
                    <button type="button" class="btn btn-yellow float-right d-block">SIGN IN</button>
                    <div class="clearfix"></div>
                    <div class="mg-t-20"></div>
                    <div class="divider "></div>
                    <div class="mg-t-20"></div>
                </div>
                <div class="col-md-12">
                    <a routerLink="/login" class="btn darkButton btn-block">Back to <span class="tx-semibold textColorThemeYellow">Sign In</span></a>
                </div>
            </div>
        </div>
    </div>
</div>
