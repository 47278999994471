import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqs;
  constructor(public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService) {
    this.authGuard.isTokenValid();
    this.ngxService.start();
    this.postLoginService.getFAQs().subscribe(
      res => {
        this.faqs = res['faqs'];
        this.ngxService.stop();
      }
    );
  }
  ngOnInit(): void {
  }

}
