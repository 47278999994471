<app-header></app-header>
<div class="fullBGLogin">
  <div class="halfBG" [style.background-image]="'url('+postLoginService.bookingData.workoutImageURL+')'"></div>
  <div class="container text-center">
    <h1 class="headMargin"></h1>
    <div *ngIf="errorMessage!=''" class="alert alert-danger ">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success ">
      {{successMessage}}
    </div>
    <div *ngIf="brainTreePaymentMethods==undefined" class="col-md-6 col-12 summaryBox">
      <p class="textHead">Loading payment</p>
      <ngx-skeleton-loader animation="progress-dark" count="3" ></ngx-skeleton-loader>
    </div>
    <div *ngIf="brainTreePaymentMethods!=undefined" class="col-md-6 col-12 summaryBox">
      <div *ngIf="this.brainTreePaymentMethods.length==0  || showNewCardUI==true">
        <h1 class="textHead">Add new payment method</h1>
        <form class="needs-validation" novalidate="">

          <div class="row">
            <div class="col-12 labelClass">
              <label for="cc-name">Cardholder Name</label>
              <div class="form-control" id="cc-name"></div>
              <small class="text-muted">Full name as displayed on card</small>
              <div class="invalid-feedback">
                Name on card is required
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 labelClass">
              <label for="cc-number">Credit card number</label>
              <div class="form-control" id="cc-number"></div>
              <div class="invalid-feedback">
                Credit card number is required
              </div>
            </div>
            <div class="col-12 labelClass">
              <label for="cc-expiration">Expiration</label>
              <div class="form-control" id="cc-expiration"></div>
              <div class="invalid-feedback">
                Expiration date required
              </div>
            </div>
            <div class="col-12 labelClass">
              <label for="cc-expiration">CVV</label>
              <div class="form-control" id="cc-cvv"></div>
              <div class="invalid-feedback">
                Security code required
              </div>
            </div>
          </div>
          <p class="errorMessageInvalid" *ngIf="addPaymentErrorMsg!=''">{{addPaymentErrorMsg}}</p>
          <hr class="mb-4">
          <div class="text-center">
            <button (click)="changeUItoAddCard(false)" class="btn btn-dark btn-block btnDiv">Choose from existing methods</button>
          <div class="mg-t-20"></div>

            <button class="btn btn-yellow btn-block" (click)="tokenizeUserDetails()" type="submit">Make Payment</button>
          </div>
        </form>
      </div>

      <div  *ngIf="this.brainTreePaymentMethods.length>0  && showNewCardUI==false">
          <h1 class="textHead">Choose a payment method</h1>
          <div class="cardsLoader" >
            <ngx-skeleton-loader animation="progress-dark" count="3" ></ngx-skeleton-loader>
          </div>
          <div id="dropin-container"></div>
          <div class="mg-t-20"></div>
          <button (click)="changeUItoAddCard(true)" class="btn btn-dark btn-block btnDiv">Add new card</button>
          <div class="mg-t-20"></div>

          <button (click)="submitDropIn()" class="btn btn-yellow btn-div mg-t-20">Submit</button>
        </div>
    </div>
  </div>
</div>
