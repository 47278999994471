import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment'
import { HttpClient,HttpHeaders } from '@angular/common/http'
import { BookingData, LoginUser } from './common.model'
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})

export class PostloginServiceService {

  bookedSession;
  loggedUser : LoginUser = {
    email : '',
    password : '',
    type:'',
    password1:'',
    mobile:'',
    otp:null,
    oldPassword:'',
    firstName:'',
    lastName:'',
    gender:'',
    countryCode:'',
    auth_token:'',
    coach_code: '',
    first_name: '',
    full_profile_pic_url: '',
    id: 0,
    last_name: '',
    medical_conditions: '',
    most_recent_trainers: '',
    notes: '',
    phone_area_code: '',
    phone_number: '',
    terms_accepted: '',
    total_trainers: '',
    verified: '',
    wallet_value: '',
  };

  bookingData: BookingData ={
    mainCategoryId:'',
    subCategoryId:'',
    addressLocationId:'',
    addressAreaId:'',
    selectedDate:'',
    selectedTime:'',
    short_address:'',
    workoutName:'',
    locationType:'',
    areaName:'',
    trainerId:'',
    noParticipants:0,
    noSessions:0,
    perSession:0,
    trainerName:'',
    coachFees:0,
    facilityFee:0,
    sessionLength:60,
    selectedTimeNew:'',
    bookingId:'',
    workoutImageURL:'',
    startTime:'',
    endTime:'',
    facilityMember:false,
    usedWalletCredits:0,
    totalCost:0,
    flow:"Normal"
  }

  constructor(private http : HttpClient,
    private router: Router,
    private storage:LocalStorageService) { }


  getProfile(){
    var headers = this.getHeaders();
    return this.http.get(environment.BaseURL+"/users/profile",headers);
  }


  updateUserProfile(userProfile){
    var headers = this.getHeaders();
    return this.http.patch(environment.BaseURL+"/users/profile",{"user":userProfile},headers);
  }

  getCategories(){
    var headers = this.getHeaders();
    return this.http.get(environment.BaseURL+"/categories",headers);
  }
  getUserLocations(){
    var headers = this.getHeaders();
    return this.http.get(environment.BaseURL+"/user_locations",headers);
  }

  getFAQs(){
    var headers = this.getHeaders();
    return this.http.get(environment.BaseURL+"/faqs",headers);
  }

  userLocationCreate(location){
    var headers = this.getHeaders();
    return this.http.post(environment.BaseURL+"/user_locations",{"user_location":location},headers);
  }

  getAreaCoordinates(){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/areas",headers);
  }

  getGymLocations(){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/gym_locations",headers);
  }

  getAvailableDates(subCatId,areaId,locType,locId){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/available_dates?data[filter][sub_category_id]="+subCatId+"&data[filter][area_id]="+areaId+"&data[filter][selected_location][location_type]="+locType+"&data[filter][selected_location][location_id]="+locId+"",headers,);
  }

  getAvailableCoaches(sessionLength,subCatId,locationType,locationId,area,date,time){
    var headers = this.getHeaders();
    var times;
    var time_type;
    if(time=="Any Time"){
      times="";
      time_type="any"
    }
     else{
      times="Optional(\""+time+"\")";
      time_type="range"
     }
      return this.http.get<any[]>(environment.BaseURL+"/filters?price_quote[number_of_sessions]=10&price_quote[sub_category_id]="+subCatId+"&price_quote[number_of_participants]=1&price_quote[session_length]="+sessionLength+"&trainer[selected_location][location_type]="+locationType+"&trainer[selected_location][location_id]="+locationId+"&trainer[time][value]="+times+"&trainer[time][time_type]="+time_type+"&trainer[area]="+area+"&trainer[date]="+date+"&trainer[session_length]="+sessionLength+"&trainer[sub_category_id]="+subCatId,headers,);
  }

  getTrainerProfile(id){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/trainers/"+id,headers,);
  }

  getTrainerPrice(id,sessionLength,noSessions=1,noParticipants=1){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/trainers/"+id+"/price_quote?session_length="+sessionLength+"&number_of_sessions="+noSessions+"&sub_category_id="+this.bookingData.subCategoryId+"&number_of_participants="+noParticipants,headers,);
  }

  getTrainerTimeSlots(id,sessionLength,noSessions,subCatId,noParticipants,areaId,selectedDate,locationType,selectedLocationId,time,facilityMember){
    var headers = this.getHeaders();
    var times;
    var time_type;
    if(time=="Any Time"){
      times="";
      time_type="any"
    }
     else{
      times="Optional(\""+time+"\")";
      time_type="range"
     }
    return this.http.get<any[]>(environment.BaseURL+"/trainers/"+id+"/time_slots?session_length="+sessionLength+"&number_of_sessions="+noSessions+"&sub_category_id="+subCatId+"&number_of_participants="+noParticipants+"&area="+areaId+"&date="+selectedDate+"&selected_location[location_type]="+locationType+"&selected_location[location_id]="+selectedLocationId+"&selected_location[facility_member]="+facilityMember+"&time[value]="+times+"&time[time_type]="+time_type,headers);
  }

  getBraintreeToken(){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/braintree/client_token",headers);
  }
  getJoompaPaymentMethods(){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/payment_methods",headers);
  }
  getBraintreePaymentMethods(){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/braintree/payment_methods",headers);
  }

  createNewBooking(walletValue,ordertotal,paymentMethodNonce,numberOfSessions,numberOfParticipants,
    trainerid,sessionLength,subCategoryid){
    var dict = {
      "order":
          {"wallet_value": walletValue}
      ,
      "braintree": {
        "payment_total":ordertotal ,
          "payment_method_nonce": paymentMethodNonce
        }
      ,
      "booking": {
          "total_sessions": numberOfSessions,
          "number_of_participants": numberOfParticipants,
          "total_price": ordertotal,
          "trainer_id": trainerid,
          "session_length": sessionLength,
          "sub_category_id": subCategoryid
      },
      "training_session" :{
        "start_time": new Date(this.bookingData.selectedDate.split('-')[2]+"-"+this.bookingData.selectedDate.split('-')[1]+"-"+this.bookingData.selectedDate.split('-')[0]+" "+ +this.bookingData.selectedTimeNew.split('-')[0].split(':')[0]+":"+ this.bookingData.selectedTimeNew.split('-')[0].split(':')[1].substr(0,2)+" "+this.bookingData.selectedTimeNew.split('-')[0].split(':')[1].substr(2)),
        "end_time":  new Date(this.bookingData.selectedDate.split('-')[2]+"-"+this.bookingData.selectedDate.split('-')[1]+"-"+this.bookingData.selectedDate.split('-')[0]+" "+ +this.bookingData.selectedTimeNew.split('-')[1].split(':')[0]+":"+ this.bookingData.selectedTimeNew.split('-')[1].split(':')[1].substr(0,2)+" "+this.bookingData.selectedTimeNew.split('-')[1].split(':')[1].substr(2)),
        "area_id": this.bookingData.addressAreaId,
        "location_type": this.bookingData.locationType,
        "location_id": this.bookingData.addressLocationId ,
        "facility_member": this.bookingData.facilityMember
      }
    }
    var headers = this.getHeaders();
    return this.http.post<any[]>(environment.BaseURL+"/bookings",dict,headers);
  }

  getBookedTrainers(){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/booked_trainers",headers);
  }

  getBookingTimeSlots(bookingId,locationType,locationId){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/bookings/"+bookingId+"/time_slots?selected_location[location_type]="+locationType+"&selected_location[location_id]="+locationId,headers);
  }

  cancelBooking(bookingId){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/training_sessions/"+bookingId+"/cancellation",headers);
  }

  postCancelBooking(bookingId,percentage){
    var postData = {"cancellation_contract": {
              "percentage": percentage
          }
        }
    var headers = this.getHeaders();
    return this.http.post<any[]>(environment.BaseURL+"/training_sessions/"+bookingId+"/cancellation",postData,headers);
  }

  createBookingTrainingSession(bookingId,startTime,endTime,areaId,locationType,locationId,facilityMember,
    walletValue=0,orderTotal=0,paymentNonce=0){
    var postData = {
          "training_session": {
          "start_time": startTime,
          "end_time": endTime,
          "area_id": areaId,
          "location_type": locationType,
          "location_id": locationId ,
          "facility_member": facilityMember
        },
      }
    if(this.bookingData.locationType=="GymLocation"){
      postData['order']=  {
        "wallet_value": walletValue
      };

      postData['braintree']= {
        "payment_total": orderTotal,
        "payment_method_nonce": paymentNonce
      };
      postData['booking']= {
        "total_sessions": this.bookingData.noSessions,
        "number_of_participants":  this.bookingData.noParticipants,
        "total_price": orderTotal,
        "trainer_id": this.bookingData.trainerId,
        "session_length": this.bookingData.sessionLength,
        "sub_category_id": this.bookingData.subCategoryId
      }
    }
    var headers = this.getHeaders();
    return this.http.post<any[]>(environment.BaseURL+"/bookings/"+bookingId+"/training_sessions",postData,headers);
  }

  getPendingReview(){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/pending_review",headers);
  }

  createReview(bookingId,like,comment,encouragement,punctuality,communication,friendliness,injury_management,attention_to_technique){
    var data = {
      "booking_id": bookingId,
       "like": like,
       "comment": comment,
       "encouragement": encouragement,
       "punctuality":punctuality,
       "communication": communication,
       "friendliness": friendliness,
       "injury_management": injury_management,
       "attention_to_technique": attention_to_technique
   }
    var headers = this.getHeaders();
    return this.http.post<any[]>(environment.BaseURL+"/reviews",{"review":data},headers);
  }
  dismissReview(bookingId){
    var data = {
        "booking_id": bookingId
    }
    var headers = this.getHeaders();
    return this.http.post<any[]>(environment.BaseURL+"/reviews",{"review_dismissed":data},headers);
  }

  getLocationFees(trainerId,locationId,locationType,isFacilityMember){
    var headers = this.getHeaders();
    return this.http.get<any[]>(environment.BaseURL+"/location_fees?trainer_id="+trainerId+"&selected_location[location_type]="+locationType+"&selected_location[location_id]="+locationId+"&selected_location[facility_member]="+isFacilityMember,headers);
  }

  deletePaymentMethod(token){
    var headers = this.getHeaders();
    return this.http.delete<any[]>(environment.BaseURL+"/braintree/payment_methods/"+token,headers);
  }

  buyPackageOnlyWithOutSession(walletValue,ordertotal,paymentMethodNonce,numberOfSessions,numberOfParticipants,
    trainerid,sessionLength,subCategoryid){
    var dict = {
      "order":
          {"wallet_value": walletValue}
      ,
      "braintree": {
        "payment_total":ordertotal ,
          "payment_method_nonce": paymentMethodNonce
        }
      ,
      "booking": {
          "total_sessions": numberOfSessions,
          "number_of_participants": numberOfParticipants,
          "total_price": ordertotal,
          "trainer_id": trainerid,
          "session_length": sessionLength,
          "sub_category_id": subCategoryid
      },
    }
    var headers = this.getHeaders();
    return this.http.post<any[]>(environment.BaseURL+"/bookings",dict,headers);
  }

  getHeaders(){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', this.storage.retrieve('auth_token'))
    }
    return header;
  }

}
