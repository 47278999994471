import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';

@Component({
  selector: 'app-your-payments',
  templateUrl: './your-payments.component.html',
  styleUrls: ['./your-payments.component.css']
})
export class YourPaymentsComponent implements OnInit {

  walletCredits='';
  constructor(public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService) {
    this.authGuard.isTokenValid();
    this.ngxService.start();
    this.postLoginService.getJoompaPaymentMethods().subscribe(
      res => {
        this.walletCredits = res['data'].payment_methods;
        this.ngxService.stop();
      }
    );
  }
  errorMessage='';
  successMessage='';

  ngOnInit(): void {
  }

  deletePaymentMethod(token){
    this.ngxService.start();
    this.postLoginService.deletePaymentMethod(token).subscribe(
      res => {
        this.successMessage = 'Payment method deleted successfully !';
            setTimeout(()=>{
              this.successMessage = '';
        }, 5000);
        this.ngxService.stop();
      },
      err=>{
        this.errorMessage = err.error.error;
            setTimeout(()=>{
              this.errorMessage = '';
        }, 5000);
        this.ngxService.stop();
      }
    );
  }
}
