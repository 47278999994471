import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookPackageFromProfileComponent } from './postlogin/book-package-from-profile/book-package-from-profile.component';
import { BookingSummaryComponent } from './postlogin/booking-summary/booking-summary.component';
import { BookingComponent } from './postlogin/booking/booking.component';
import { CalendarComponent } from './postlogin/calendar/calendar.component';
import { CoachPackageComponent } from './postlogin/coach-package/coach-package.component';
import { CoachProfileComponent } from './postlogin/coach-profile/coach-profile.component';
import { EditProfileComponent } from './postlogin/edit-profile/edit-profile.component';
import { FaqComponent } from './postlogin/faq/faq.component';
import { LocationComponent } from './postlogin/location/location.component';
import { LogoutComponent } from './postlogin/logout/logout.component';
import { PaymentPageComponent } from './postlogin/payment-page/payment-page.component';
import { ProfileComponent } from './postlogin/profile/profile.component';
import { SessionSuccessComponent } from './postlogin/session-success/session-success.component';
import { ShowCoachesComponent } from './postlogin/show-coaches/show-coaches.component';
import { YourLocationsComponent } from './postlogin/your-locations/your-locations.component';
import { YourPaymentsComponent } from './postlogin/your-payments/your-payments.component';
import { ForgotPasswordComponent } from './prelogin/forgot-password/forgot-password.component';
import { LoginComponent } from './prelogin/login/login.component'
import { PhoneNumberComponent } from './prelogin/phone-number/phone-number.component';
import { SignUpComponent } from './prelogin/sign-up/sign-up.component';
import { VerifyOTPComponent } from './prelogin/verify-otp/verify-otp.component';


export const routes: Routes = [
  {path:'',component: LoginComponent},
  {path:'login',component: LoginComponent},
  {path:'forgotPassword',component: ForgotPasswordComponent},
  {path:'signUp',component: SignUpComponent},
  {path:'verifyOTP',component: VerifyOTPComponent},
  {path:'booking',component: BookingComponent},
  {path:'location',component: LocationComponent},
  {path:'bookPackageForCoach/:id',component: BookPackageFromProfileComponent},
  {path:'phoneNumber',component: PhoneNumberComponent},
  {path:'calendar',component: CalendarComponent},
  {path:'showCoaches',component: ShowCoachesComponent},
  {path:'coachProfile',component: CoachProfileComponent},
  {path:'bookingSummary',component: BookingSummaryComponent},
  {path:'logout',component: LogoutComponent},
  {path:'profile',component: ProfileComponent},
  {path:'sessionSuccess',component:SessionSuccessComponent},
  {path:'editProfile',component:EditProfileComponent},
  {path:'yourLocations',component:YourLocationsComponent},
  {path:'paymentMethods',component:YourPaymentsComponent},
  {path:'faq',component:FaqComponent},
  {path:'makePayment',component:PaymentPageComponent},
  {path:'coachPackage/:id',component:CoachPackageComponent},
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
