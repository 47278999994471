import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocalStorageService } from 'ngx-webstorage';
import { PreloginServiceService } from 'src/app/shared/prelogin-service.service';
declare let fbq:Function;

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOTPComponent implements OnInit {


  extras;
  successMessage ='';
  errorMessage ='';
  phoneNum = {phone_number:null,code:null,country_code:null};
  constructor(public router:Router,
    public loginService:PreloginServiceService,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private storage:LocalStorageService) {
      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation().extras.state) {
          this.phoneNum.phone_number   = this.router.getCurrentNavigation().extras.state.phone;
          this.phoneNum.country_code =this.router.getCurrentNavigation().extras.state.countryCode;
        }
      });
     }

  ngOnInit() {
    if(this.phoneNum.phone_number==null){
      this.router.navigate(['signUp']);
    }
  }
  sendOTP(code){
    this.phoneNum.code = code;
    this.ngxService.start();
    this.loginService.verifyOTP(this.phoneNum).subscribe(
      res=>{
        this.loginService.getProfile().subscribe(
          res=>{
            fbq('track', 'CompleteRegistration',{value: 0.00,currency: 'MYR'});
            this.storage.store('auth_token', res['user'].auth_token);
            var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
            this.storage.store('userData',data)
            this.router.navigate(['booking']);
            this.ngxService.stop();
          },
          err=>{
            this.errorMessage = err.error.error;
            this.ngxService.stop();
          }
        )
      },
      err=>{
         this.errorMessage =err.error.error;
        this.ngxService.stop();
      }
    )
  }
  onOtpChange(event){
    if(event.length==4){
      this.sendOTP(event);
    }
  }
}
