<app-header></app-header>
<div class="fullBGLogin">
  <div class="topWidth">
  </div>
  <div class="container">
    <h1 class="headingH1">Your Locations</h1>
    <div *ngIf="errorMessage!=''" class="alert alert-danger text-center">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success text-center">
        {{successMessage}}
      </div>
    <div class="mainBox row">
      <div class="col-12 col-md-7 leftSide" >
        <h3><a class="whiteLink mg-r-10" routerLink="/profile"><i class="fa fa-arrow-left"></i></a> Locations</h3>
        <div  class="scrollbar locationDiv" id="style-9"  *ngIf="locations!=undefined">
          <div *ngFor="let item of locations" class="locs">
            <div *ngIf="locations.length==0">
              No saved locations
             </div>
            <div class="loc row">
              <div class="viewL">
                <p class="head">{{item.short_address}}</p>
                <p class="foor">{{item.full_address}}</p>
              </div>
              <!-- <div class="viewR">
                <a data-id="{{item.id}}" class="yellowLink" href=""><i class="fa fa-pencil"></i></a>
               </div> -->
            </div>
          </div>
        </div>

      </div>
      <div class="col-12 col-md-5 rightSide">
        <h3>Add new location</h3>
        <div class="form-group">
            <input [(ngModel)]="newPlaceAddress"  ngx-google-places-autocomplete  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"  class="form-control darkInputBox" placeholder="Enter address">
        </div>
        <div class="col-md-6" *ngIf="!disableButton">
          <button (click)="clear()" class="btn btn-dark mg-r-10">CLEAR</button>
          <button [disabled]="disableButton" (click)="saveNewLocation()" class="btn btn-yellow">SAVE</button>
      </div>
      </div>
    </div>
  </div>
</div>
