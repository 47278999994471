<div class="fullBGLogin newBG">
  <img src="../../../assets/img/logo.png" class="joompLogo">
    <div class="container">
        <div class="row">
            <div class="col-md-6 loginPageBG ">
                <div class="col-md-12 loginLeftSide pd-t-100">
                    <img src="../../../assets/img/group-7.svg" class="Group-7">
                    <h3>Quality</h3>
                    <p>The city’s top freelancers, on the country’s leading platform, all assessed by our internal quality assurance processes. </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-8.svg" class="Group-7">
                    <h3>Convenience</h3>
                    <p>From ease of booking to customised coaching, every step is focused on you.  </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-9.svg" class="Group-7">
                    <h3>Guarantee</h3>
                    <p>Our professionals have been delivering results for customers for years. Read verified reviews from real customers before you proceed with one of our top performers. </p>
                </div>
            </div>
            <div class="col-md-6 pd-t-100 rightSideLoginComp">
                <div *ngIf="genericErrorMessage!=''" class="alert alert-danger errorDiv">
                    {{genericErrorMessage}}
                </div>
                <div *ngIf="genericSuccessMessage!=''" class="alert alert-success">
                  {{genericSuccessMessage}}
              </div>
                <p class="Welcome-back-log-in">Welcome Back</p>
                <p class="Welcome-back-log-in">Login below</p>
                <button (click)="signInWithFB()" class="btn btn-primary btn-block">
                    <i class="fa fa-facebook-square pd-r-20"></i>Continue with facebook
                </button>
                <div class="divider-text">or Sign in via</div>
                <form  #loginForm="ngForm"  (ngSubmit)="onSubmit(loginForm)">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" [ngClass]="{ 'is-invalid': email.invalid && (email.dirty || email.touched)}" #email="ngModel" [(ngModel)]="loginService.loggedUser.email" pattern="\S+@\S+\.\S+" name="email" class="form-control form-control-lg text-center darkInputBox" placeholder="Enter your email"  required >
                                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="errorDiv">
                                  <div *ngIf="email.errors.required">
                                      Email is required.
                                  </div>
                                  <div *ngIf="email.errors?.pattern">
                                    Enter valid email address
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="password" [ngClass]="{ 'is-invalid': password.invalid && (password.dirty || password.touched)}" #password="ngModel"  [(ngModel)]="loginService.loggedUser.password" name="password" class="form-control form-control-lg text-center darkInputBox" placeholder="Enter a password"  required >
                                <div *ngIf="password.invalid && (password.dirty || password.touched)" class="errorDiv">
                                  <div *ngIf="password.errors.required">
                                      Password is required.
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div *ngIf="errorMessage!=''" class="alert alert-danger errorDiv">
                    {{errorMessage}}
                  </div>
                  <div *ngIf="successMessage!=''" class="alert alert-success errorDiv">
                      {{successMessage}}
                    </div>
                <div class="row">
                    <div class="col-md-6">
                        <a (click)="goToLink('https://www.joompa.com.my/users/password_resets/new')" href="#" class="yellowLink">Forgot Password</a>
                    </div>
                    <div class="col-md-6">
                        <button [disabled]="!loginForm.valid"  type="submit" class="btn btn-yellow float-right">SIGN IN</button>
                    </div>
                </div>
                </form>
                <div class="divider"></div>
                <a routerLink="/signUp" type="button" class="btn darkButton btn-block">No account yet? <span class="tx-semibold textColorThemeYellow">Register</span></a>
            </div>
        </div>
    </div>

</div>
