import {   Component,   OnInit,  TemplateRef,  ViewChild} from '@angular/core';
import {  Router} from '@angular/router';
import {  NgxUiLoaderService  } from 'ngx-ui-loader';
import {  AuthGuardService  } from 'src/app/shared/auth-guard.service';
import {   PostloginServiceService } from 'src/app/shared/postlogin-service.service';
import * as braintree from 'braintree-web';
import {  NgbModal,  ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import * as dropin from 'braintree-web-drop-in'
declare var $:any;
declare let fbq:Function;

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.css']
})
export class PaymentPageComponent implements OnInit {

  constructor(private modalService: NgbModal,
    public router: Router,
    public authGuard: AuthGuardService,
    public postLoginService: PostloginServiceService,
    private ngxService: NgxUiLoaderService) {
    this.authGuard.isTokenValid();

    }
  data;
  totalCost;
  errorMessage = '';
  successMessage = '';
  summary;
  math = Math;
  hostedFieldsInstance: braintree.HostedFields;
  clientToken;
  ngOnInit(): void {
    if (this.postLoginService.bookingData.trainerName == '') {
      //this.router.navigate(['showCoaches']);
    }
    this.ngxService.start();
    this.postLoginService.getBraintreeToken().subscribe(
      res => {
        this.clientToken = res['data'].client_token;
        this.postLoginService.getBraintreePaymentMethods().subscribe(
            res => {
              this.brainTreePaymentMethods = res['data'].payment_methods;
              if(this.brainTreePaymentMethods.length>0){
                $( document ).ready(function() {
                  $('#dropin-container').bind('DOMSubtreeModified', function(){
                    $('.cardsLoader').hide();
                  });
                });
                this.createCardsUI();
              }
              else{
                this.createBraintreeUI()
              }
              this.ngxService.stop();
            }
          );
      });
  }



  createBraintreeUI() {
    braintree.client.create({
      authorization: this.clientToken
    }).then((clientInstance) => {
      braintree.hostedFields.create({
        client: clientInstance,
        styles: {
          'input': {
            'font-size': '14px',
            'color': '#fff'
          },
          'input.invalid': {
            'color': 'red'
          }
        },
        fields: {
          cardholderName: {
            selector: '#cc-name',
            placeholder: 'Name as it appears on your card'
          },
          number: {
            selector: '#cc-number',
            placeholder: '4111 1111 1111 1111'
          },
          cvv: {
            selector: '#cc-cvv',
            placeholder: '123'
          },
          expirationDate: {
            selector: '#cc-expiration',
            placeholder: 'MM / YY'
          }
        }
      }).then((hostedFieldsInstance) => {

        this.hostedFieldsInstance = hostedFieldsInstance;

        hostedFieldsInstance.on('focus', (event) => {
          const field = event.fields[event.emittedBy];
          const label = this.findLabel(field);
          label.classList.remove('filled'); // added and removed css classes
          // can add custom code for custom validations here
        });

        hostedFieldsInstance.on('blur', (event) => {
          const field = event.fields[event.emittedBy];
          const label = this.findLabel(field); // fetched label to apply custom validations
          // can add custom code for custom validations here
        });

        hostedFieldsInstance.on('empty', (event) => {
          const field = event.fields[event.emittedBy];
          // can add custom code for custom validations here
        });

        hostedFieldsInstance.on('validityChange', (event) => {
          const field = event.fields[event.emittedBy];
          const label = this.findLabel(field);
          if (field.isPotentiallyValid) { // applying custom css and validations
            label.classList.remove('invalid');
          } else {
            label.classList.add('invalid');
          }
          // can add custom code for custom validations here
        });
      });
    });
  }
  addPaymentErrorMsg = '';
  // Tokenize the collected details so that they can be sent to your server
  // called from the html when the 'Pay' button is clicked
  tokenizeUserDetails() {
    this.hostedFieldsInstance.tokenize().then((payload) => {
      if(this.postLoginService.bookingData.flow=="bookPackageForCoach"){
        this.ngxService.start();
        this.postLoginService.buyPackageOnlyWithOutSession(this.postLoginService.bookingData.usedWalletCredits, this.postLoginService.bookingData.totalCost, payload['nonce'],
          this.postLoginService.bookingData.noSessions, this.postLoginService.bookingData.noParticipants,
          this.postLoginService.bookingData.trainerId, this.postLoginService.bookingData.sessionLength, this.postLoginService.bookingData.subCategoryId
        ).subscribe(
          res => {
            fbq('track', 'Purchase',{value: this.totalCost,currency: 'MYR'});
            this.router.navigate(['sessionSuccess']);
            this.ngxService.stop();
          },
          err => {
            this.errorMessage= err.error.error;
            setTimeout(() => {
              this.errorMessage= '';
            }, 3000);
            this.ngxService.stop();
          }
        );
      }
      else{
        if(this.postLoginService.bookingData.bookingId!=""){
          this.ngxService.start();
          this.postLoginService.createBookingTrainingSession(
            this.postLoginService.bookingData.bookingId,
            this.postLoginService.bookingData.startTime,
            this.postLoginService.bookingData.endTime,
            this.postLoginService.bookingData.addressAreaId,
            this.postLoginService.bookingData.locationType,
            this.postLoginService.bookingData.addressLocationId,
            this.postLoginService.bookingData.facilityMember,
            this.postLoginService.bookingData.usedWalletCredits,this.postLoginService.bookingData.totalCost,payload['nonce']
          ).subscribe(
            res=>{
              fbq('track', 'Purchase',{value: this.postLoginService.bookingData.totalCost,currency: 'MYR'});
              this.router.navigate(['sessionSuccess']);
              this.ngxService.stop();
            },
            err=>{
              this.errorMessage=err.error.error;
              this.ngxService.stop();
              setTimeout(() => {
                this.errorMessage='';
              }, 3000);
            }
          );
        }
        else{
          this.ngxService.start();
          this.postLoginService.createNewBooking(this.postLoginService.bookingData.usedWalletCredits, this.postLoginService.bookingData.totalCost, payload['nonce'],
            this.postLoginService.bookingData.noSessions, this.postLoginService.bookingData.noParticipants,
            this.postLoginService.bookingData.trainerId, this.postLoginService.bookingData.sessionLength, this.postLoginService.bookingData.subCategoryId
          ).subscribe(
            res => {
              fbq('track', 'Purchase',{value: this.postLoginService.bookingData.totalCost,currency: 'MYR'});
              this.router.navigate(['sessionSuccess']);
              this.ngxService.stop();
            },
            err => {
              this.addPaymentErrorMsg= err.error.error;
              setTimeout(() => {
                this.addPaymentErrorMsg= '';
              }, 3000);
              this.ngxService.stop();
            }
          );
        }
      }
    }).catch((error) => {
      if (error.code = "HOSTED_FIELDS_FIELDS_INVALID") {
        this.addPaymentErrorMsg = "Invalid card information. Please enter valid card information";
        setTimeout(() => {
          this.addPaymentErrorMsg = '';
        }, 3000);
      }
    });
  }
  // Fetches the label element for the corresponding field
  findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector('label[for="' + field.container.id + '"]');
  }

  dropIninstance;
  braintreeIsReady;
  createCardsUI(){
    this.postLoginService.getBraintreeToken().subscribe(
      res => {
        this.clientToken = res['data'].client_token;
        var button = document.querySelector('#submitButton');
        dropin.create({
          authorization: this.clientToken,
          container: '#dropin-container',
          card: {
            cardholderName: {
              required: true
            },
            overrides: {
              styles: {
                input: {
                  color: '#fff',
                },
              },
              ':focus':{
                color: '#fff',
              }
            }
          }
        },  (createErr, instance) => {
          if (createErr) {
            console.error(createErr);
            return;
          }else{
            this.dropIninstance = instance;
            this.braintreeIsReady = true;
          }
      });
    });
  }
  walletCredits;
  showJoompaWalletBox = false;
  usedWalletCredits;
  initialTotalCost=0;

  brainTreePaymentMethods;

  submitDropIn(){
    this.dropIninstance.requestPaymentMethod( (err, payload)=> {
      console.log(payload)
      if(this.postLoginService.bookingData.flow=="bookPackageForCoach"){
        this.ngxService.start();
        this.postLoginService.buyPackageOnlyWithOutSession(this.postLoginService.bookingData.usedWalletCredits, this.postLoginService.bookingData.totalCost, payload['nonce'],
          this.postLoginService.bookingData.noSessions, this.postLoginService.bookingData.noParticipants,
          this.postLoginService.bookingData.trainerId, this.postLoginService.bookingData.sessionLength, this.postLoginService.bookingData.subCategoryId
        ).subscribe(
          res => {
            fbq('track', 'Purchase',{value: this.totalCost,currency: 'MYR'});
            this.router.navigate(['sessionSuccess']);
            this.ngxService.stop();
          },
          err => {
            this.errorMessage= err.error.error;
            setTimeout(() => {
              this.errorMessage= '';
            }, 3000);
            this.ngxService.stop();
          }
        );
      }
      else{
        if(this.postLoginService.bookingData.bookingId!=""){
          this.ngxService.start();
          this.postLoginService.createBookingTrainingSession(
            this.postLoginService.bookingData.bookingId,
            this.postLoginService.bookingData.startTime,
            this.postLoginService.bookingData.endTime,
            this.postLoginService.bookingData.addressAreaId,
            this.postLoginService.bookingData.locationType,
            this.postLoginService.bookingData.addressLocationId,
            this.postLoginService.bookingData.facilityMember,
            this.postLoginService.bookingData.usedWalletCredits,this.postLoginService.bookingData.totalCost,payload['nonce']
          ).subscribe(
            res=>{
              fbq('track', 'Purchase',{value: this.postLoginService.bookingData.totalCost,currency: 'MYR'});
              this.router.navigate(['sessionSuccess']);
              this.ngxService.stop();
            },
            err=>{
              this.errorMessage=err.error.error;
              this.ngxService.stop();
              setTimeout(() => {
                this.errorMessage='';
              }, 3000);
            }
          );
        }
        else{
          this.ngxService.start();
          this.postLoginService.createNewBooking(this.postLoginService.bookingData.usedWalletCredits, this.postLoginService.bookingData.totalCost, payload['nonce'],
            this.postLoginService.bookingData.noSessions, this.postLoginService.bookingData.noParticipants,
            this.postLoginService.bookingData.trainerId, this.postLoginService.bookingData.sessionLength, this.postLoginService.bookingData.subCategoryId
          ).subscribe(
            res => {
              fbq('track', 'Purchase',{value: this.postLoginService.bookingData.totalCost,currency: 'MYR'});
              this.router.navigate(['sessionSuccess']);
              this.ngxService.stop();
            },
            err => {
              this.errorMessage= err.error.error;
              setTimeout(() => {
                this.errorMessage= '';
              }, 3000);
              this.ngxService.stop();
            });
          }
        }
    });
  }

  showNewCardUI=false;
  changeUItoAddCard(bool){
    this.showNewCardUI=bool;
    if(this.showNewCardUI){
      this.createBraintreeUI()
    }else{
      this.createCardsUI()
    }
    $( document ).ready(function() {
      $('#dropin-container').bind('DOMSubtreeModified', function(){
        $('.cardsLoader').hide();
      });
    });
  }
}
