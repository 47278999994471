import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { environment} from '../../../environments/environment'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor( private http : HttpClient, private storage:LocalStorageService) { }
  userData;
  auth_token;
  ngOnInit(): void {
    this.userData = this.storage.retrieve('userData')
    this.auth_token = this.storage.retrieve('auth_token');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', this.auth_token)
    }
     this.http.get(environment.BaseURL+"/users/profile",header).subscribe(
       res=>{
        var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
        this.storage.store('userData',data);
       });
  }
}
