<app-header></app-header>
<div class="fullBGLogin">
  <div class="topWidth">
  </div>
  <div class="container text-center">
    <h1 class="headingH1">Your Profile</h1>
    <div class="mainBox row">
      <div class="col-12 col-md-7 leftSide" >
        <div *ngIf="bookedTrainers!=undefined">
          <div *ngIf="bookedTrainers.length==0" class="noBookings">
            <h3>No Sessions</h3>
            <p>
              Upcoming Sessions with your coaches will show up here. Book a session below what you want to work out on
            </p>
            <div class="col-12">
              <button routerLink="/booking" class="btn btn-yellow">Book a session</button>
            </div>
          </div>
          <div *ngIf="bookedTrainers.length>0">
            <h3 class="trainerFont">Your Coaches</h3>
            <div *ngFor="let item of  bookedTrainers;let i=index" class="media" (click)="openPackage(i,item.id)">
              <div class="avatar avatar-sm">
                <img [src]="item.full_profile_pic_url" class="rounded-circle" alt="">
              </div>
              <div class="media-body mg-l-10">
                <h6 class="tx-13 mg-b-3">{{item.first_name}} {{item.last_name}}</h6>
                <span class="tx-12">{{item.bookings_count}} booking<ng-container *ngIf="item.bookings_count>1">s</ng-container> made</span>
              </div><!-- media-body -->
              <div class="viewR">
               <a  class="yellowLink" >View</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 rightSide">
        <h3>Account Preferences</h3>
        <div class="profile row no-gutters">
          <div class="col-3">
            <img [src]="postLoginService.loggedUser?.full_profile_pic_url">
          </div>
          <div class="col-9">
            <h4>{{postLoginService.loggedUser?.first_name}} {{postLoginService.loggedUser?.last_name}}</h4>
            <p><a class="whiteLink" routerLink="/editProfile">Edit Profile & Account</a></p>
          </div>
        </div>
        <div class="profileBelow">
          <div class="col-12 belowBox">
           <a class="whiteLink" routerLink="/yourLocations"> <i class="fa fa-map-marker"></i> Your location</a>
          </div>
          <div class="col-12 belowBox">
           <a class="whiteLink" routerLink="/paymentMethods">   <i class="fa fa-credit-card"></i> Your Payments</a>
          </div>
          <div class="col-12 belowBox">
           <a class="whiteLink" routerLink="/faq">   <i class="fa fa-question-circle"></i> About & FAQ</a>
          </div>
        </div>
        <div routerLink="/logout" class="logoutBottom">
          Logout
        </div>
      </div>
    </div>
  </div>
</div>
