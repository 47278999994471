<div class="fullBGLogin newBG">
  <img src="../../../assets/img/logo.png" class="joompLogo">
    <div class="container">
        <div class="row">
            <div class="col-md-6 loginPageBG">
                <div class="col-md-12 loginLeftSide pd-t-100">
                    <img src="../../../assets/img/group-7.svg" class="Group-7">
                    <h3>Quality</h3>
                    <p>The city’s top freelancers, on the country’s leading platform, all assessed by our internal quality assurance processes. </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-8.svg" class="Group-7">
                    <h3>Convenience</h3>
                    <p>From ease of booking to customized coaching, every step is focused on you.  </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-9.svg" class="Group-7">
                    <h3>Guarantee</h3>
                    <p>Our professionals have been delivering results for customers for years. Read verified reviews from real customers before you proceed with one of our top performers. </p>
                </div>
            </div>
            <div class="col-md-6 pd-t-100 rightSideLoginComp">
                <p class="Welcome-back-log-in">Verifying your number</p>
               <p class="tx-white text-center">We’ve sent a verification code to {{ this.phoneNum.country_code}} {{phoneNum.phone_number}}</p>
                <div class="col-md-12">
                    <div class="form-group text-center">
                        <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4, inputClass:'darkInputBox'}"></ng-otp-input>
                    </div>
                    <div class="clearfix"></div>
                    <div *ngIf="errorMessage!=''" class="alert alert-danger errorDiv">
                        {{errorMessage}}
                    </div>
                    <div *ngIf="successMessage!=''" class="alert alert-success errorDiv">
                          {{successMessage}}
                    </div>
                </div>
                <div class="col-md-12 mg-t-20">
                    <a routerLink="/phoneNumber" class="btn darkButton btn-block">CHANGE NUMBER</a>
                </div>
            </div>
        </div>
    </div>
</div>
