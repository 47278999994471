<app-header></app-header>
<div class="fullBGLogin">
  <div class="topWidth">
  </div>
  <div class="container">
    <h1 class="headingH1">Your Package with {{coach?.first_name}}</h1>
    <div *ngIf="errorMessage!=''" class="alert alert-danger ">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success ">
      {{successMessage}}
    </div>
    <div class="mainBox row" >
      <div class="col-12 col-md-4 leftSide" *ngIf="coach!=undefined">
        <h3 class="trainerFont"><a class="whiteLink mg-r-10" routerLink="/profile"><i class="fa fa-arrow-left"></i></a>Your Package</h3>

        <div class="btns">
          <button (click)="show(0)" [ngClass]="{'selectedBtn' : selectedBtn[0]==true}" type="button" class="btn btn-outline-light">On-Going</button>
          <button (click)="show(1)" [ngClass]="{'selectedBtn' : selectedBtn[1]==true}"  type="button" class="btn btn-outline-light">Completed</button>
        </div>
        <div  class="scrollbar divHeight" id="style-9" >
          <div class="onGoing" [ngClass]="{'d-none' : selectedBtn[0]==false}">
          <ng-container *ngFor="let booking of coach.bookings">
            <div  *ngIf="booking.completed==false && booking.is_expired==false" class="booking"  [ngClass]="{'selected': selectedItem == booking.id}" (click)="listClick($event, booking.id)" >
              <div class="row">
                <div class="bkLeft">
                  <p class="sessionName">{{booking.sub_category.title_with_category}}</p>
                  <p class="sessionRemaining"><span>{{booking.remaining_sessions}} session<ng-container *ngIf="booking.remaining_sessions>1">s</ng-container> </span> remaining <span *ngIf="booking.is_expired">(Expired)</span></p>
                </div>
                <!-- <div class="bkRight">
                  <div *ngIf="booking.remaining_sessions>0" href="#" (click)="bookNow(booking.id)" class="yellowLink" href="">Book now</div>
                  <div *ngIf="booking.training_sessions.length>0" href="#" data-id="{{booking.id}}" class="yellowLink" href="">View</div>
                </div> -->
              </div>
            </div>
          </ng-container>
        </div>
        <div [ngClass]="{'d-none' : selectedBtn[1]==false}" class="completed" >
          <ng-container *ngFor="let booking of coach.bookings">
            <div  *ngIf="booking.completed==true || booking.is_expired==true" class="booking"  [ngClass]="{'selected': selectedItem == booking.id}" (click)="listClick($event, booking.id)" >
              <p class="sessionName">{{booking.sub_category.title_with_category}}</p>
              <p class="sessionRemaining"><span>{{booking.remaining_sessions}} session<ng-container *ngIf="booking.remaining_sessions>1">s</ng-container></span> remaining <span *ngIf="booking.is_expired">(Expired)</span></p>
            </div>
          </ng-container>
        </div>
        </div>
        <div class="mg-t-10">
          <a routerLink="/bookPackageForCoach/{{coach.id}}"   type="button" class="btn btn-yellow btn-block">Book new package</a>
        </div>
      </div>
      <div class="col-12 col-md-4 rightSide" *ngIf="selectedSession!=undefined">
        <h3>Session Summary</h3>
        <div  class="scrollbar divHeight" id="style-9" >
          <div class="icons">
            <p><i class="fa fa-user"></i>{{coach.first_name}} {{coach.last_name}}</p>
            <!-- <p><i class="fa fa-calendar"></i>{{selectedSession.created_at | date : 'dd MMM,yyyy'}}</p>
            <p><i class="fa fa-clock-o"></i>{{selectedSession.created_at | date : 'shortTime'}}</p>
            <p><i class="fa fa-map-marker"></i>Coach Name</p> -->
            <p><i class="fa fa-users"></i>{{selectedSession.number_of_participants}}</p>
            <p><i class="fa fa-undo"></i>{{selectedSession.session_length}}</p>
            <p><i class="fa fa-align-left"></i>Total {{selectedSession.total_sessions}} session<ng-container *ngIf="selectedSession.total_sessions>1">s</ng-container></p>
            <p><i class="fa fa-info-circle"></i>{{selectedSession.sub_category.explanation}}</p>
            <p><i class="fa fa-ellipsis-h"></i>Remaining credits {{selectedSession.remaining_sessions}}</p>

            <!-- <button *ngFor="let item of selectedSession.training_sessions" (click)="cancelBooking(item.id)" type="button" class="btn btn-yellow btn-block">Cancel Booking at {{convertTimeStartAndEnd(item.start_time,item.end_time)}}</button> -->
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 rightSide1">
        <div  class="scrollbar divHeight1" id="style-9" >
          <div *ngIf="usedSlot==undefined && selectedSession!=undefined" style="height: 100%;">
          <h3 class="tfont">Session Details</h3>

            <div class="btns">
              <button (click)="selectDetails(0)" [ngClass]="{'selectedBtn' : selectedDetailsBtn[0]==true}" type="button" class="btn btn-outline-light">Upcomming</button>
              <button (click)="selectDetails(1)" [ngClass]="{'selectedBtn' : selectedDetailsBtn[1]==true}"  type="button" class="btn btn-outline-light">Past</button>
            </div>
            <div class="bookNow" *ngIf="selectedDetailsBtn[0]==true">
              <ng-container *ngFor="let item of selectedSession.training_sessions ;let i = index">
                <div class="box"  *ngIf="!checkIfCompleted(item)">Used Slot {{i+1}}  <ng-container *ngIf="item.status!='confirmed'">- CANCELLED</ng-container> <div (click)="view(item.id)" class="yellowLink" href=""> View</div> </div>
              </ng-container>
              <div class="box" *ngFor="let item of counter(selectedSession.remaining_sessions) ;let i = index">Open Slot {{i+1}} <div  *ngIf="!selectedSession.is_expired" (click)="bookNow(selectedSession.id)" class="yellowLink" href="">Book now</div> <div  *ngIf="selectedSession.is_expired"  class="yellowLink" href="">EXPIRED</div>  </div>
            </div>
            <div class="bookNow" *ngIf="selectedDetailsBtn[1]==true">
              <ng-container *ngFor="let item of selectedSession.training_sessions ;let i = index">
                <div class="box"  *ngIf="checkIfCompleted(item)">Used Slot {{i+1}}  <ng-container *ngIf="item.status!='confirmed'">- CANCELLED</ng-container> <div (click)="view(item.id)" class="yellowLink" href=""> View</div> </div>
              </ng-container>
            </div>
          </div>
          <div  *ngIf="usedSlot!=undefined">
            <div (click)="usedSlot=undefined" class="yellowLink" > <i  class="fa fa-arrow-left mg-r-10 "></i>Back to slots</div>
            <p><i class="fa fa-map-marker"></i>{{usedSlot.short_address}}</p>
            <p><i class="fa fa-calendar"></i>{{usedSlot.start_time | date : 'dd MMM,yyyy'}}</p>
            <p><i class="fa fa-clock-o"></i>{{usedSlot.start_time | date:"shortTime"}} - {{usedSlot.end_time | date:"shortTime"}}</p>
            <p><i class="fa fa-info-circle"></i>{{usedSlot.status}}</p>
            <p (click)="cancelBooking(selectedSession.id,usedSlot.id)" *ngIf="!checkIfCompleted(usedSlot) && usedSlot.status=='confirmed'" class="text-center yellowLink">Cancel Booking</p>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>
