import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';
declare var $: any;

@Component({
  selector: 'app-show-coaches',
  templateUrl: './show-coaches.component.html',
  styleUrls: ['./show-coaches.component.css']
})
export class ShowCoachesComponent implements OnInit {

  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService) { }

  errorMessage='';
  successMessage='';
  coaches;
  videoUrl='';
  ngOnInit(): void {
    if(this.postLoginService.bookingData.selectedTime==''){
      this.router.navigate(['calendar']);
    }
    this.ngxService.start();
    if(this.postLoginService.bookingData.locationType=="GymLocation"){
      this.postLoginService.getAvailableCoaches(this.postLoginService.bookingData.sessionLength,this.postLoginService.bookingData.subCategoryId,
        this.postLoginService.bookingData.locationType,this.postLoginService.bookingData.addressLocationId,
        this.postLoginService.bookingData.areaName,this.postLoginService.bookingData.selectedDate,this.postLoginService.bookingData.selectedTime).subscribe(
        res=>{
          this.coaches=res['trainers'];
          this.ngxService.stop();
        }
      );
    }
    else{
      this.postLoginService.getAvailableCoaches(this.postLoginService.bookingData.sessionLength,this.postLoginService.bookingData.subCategoryId,
        this.postLoginService.bookingData.locationType,this.postLoginService.bookingData.addressAreaId,
        this.postLoginService.bookingData.areaName,this.postLoginService.bookingData.selectedDate,this.postLoginService.bookingData.selectedTime).subscribe(
        res=>{
          this.coaches=res['trainers'];
          this.ngxService.stop();
        }
      );
    }
  }
  next(trainerId){
    this.postLoginService.bookingData.trainerId=trainerId;
    this.router.navigate(['coachProfile']);
  }

  open(url){
    this.videoUrl =url;
    $(document).ready(function(){
      $('#exampleModal').modal('show');
      $('#exampleModal').on('hidden.bs.modal', function () {
        $('#myVideo')[0].pause();
      })
    });
  }
}
