import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public router:Router,
    private storage:LocalStorageService) {
      this.clearAndRoute();
    }

  async clearAndRoute() {
    await this.storage.clear('auth_token');
    await this.storage.clear('userData');
    await this.router.navigate(['login'],{queryParams:{logout:1}});
  }

  ngOnInit(): void {

  }

}
