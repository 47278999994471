<div class="fullBGLogin newBG">
  <img src="../../../assets/img/logo.png" class="joompLogo">
    <div class="container">
        <div class="row">
            <div class="col-md-6 loginPageBG ">
                <div class="col-md-12 loginLeftSide pd-t-100">
                    <img src="../../../assets/img/group-7.svg" class="Group-7">
                    <h3>Quality</h3>
                    <p>The city’s top freelancers, on the country’s leading platform, all assessed by our internal quality assurance processes. </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-8.svg" class="Group-7">
                    <h3>Convenience</h3>
                    <p>From ease of booking to customised coaching, every step is focused on you.  </p>
                </div>
                <div class="col-md-12 loginLeftSide pd-t-30">
                    <img src="../../../assets/img/group-9.svg" class="Group-7">
                    <h3>Guarantee</h3>
                    <p>Our professionals have been delivering results for customers for years. Read verified reviews from real customers before you proceed with one of our top performers. </p>
                </div>
            </div>
            <div class="col-md-6 pd-t-100 rightSideLoginComp">
                <p class="Welcome-back-log-in">Enter phone number</p>
            <p class="tx-white text-center">Please verify your number before proceeding to receive a free fitness consultation.</p>

                <form class="mg-t-30"  id="sendOTPForm" #sendOTPForm="ngForm" (ngSubmit)="sendOTP(sendOTPForm)">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                      <select #country_code="ngModel" name="country_code" [(ngModel)]="countryCode"  class="custom-select darkInputBox">
                        <option selected  value="+60">+60</option>
                        <option value="+852">+852</option>
                        <option value="+65">+65</option>
                        <option value="+61">+61</option>
                        <option value="+91">+91</option>
                      </select>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-group">
                        <div class="input-group mg-b-10">
                          <input type="text"  [ngClass]="{ 'is-invalid': phone_number.invalid && (phone_number.dirty || phone_number.touched)}" #phone_number="ngModel" [(ngModel)]="preloginService.loggedUser.mobile" pattern="^[0-46-9]*[0-9]{7,8}$"  name="phone_number" class="form-control form-control-lg text-center darkInputBox" placeholder="Enter your phone number" required >
                        </div>
                          <div *ngIf="phone_number.invalid && (phone_number.dirty || phone_number.touched)" class="errorDiv">
                            <div *ngIf="phone_number.errors.required">
                                Phone number is required
                            </div>
                            <div *ngIf="phone_number.errors?.pattern">
                              Enter valid phone number
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>


                    <div class="row">
                        <div class="col-md-12">
                            <button [disabled]="!sendOTPForm.valid"  form="sendOTPForm" type="submit" class="btn btn-yellow btn-block mg-t-20">
                                SEND CODE
                              </button>
                        </div>
                    </div>

                  </form>
                <div *ngIf="errorMessage!=''" class="alert alert-danger errorDiv">
                    {{errorMessage}}
                </div>
                <div *ngIf="successMessage!=''" class="alert alert-success errorDiv">
                      {{successMessage}}
                </div>

            </div>
        </div>
    </div>
</div>
