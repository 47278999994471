<app-header></app-header>
<div class="fullBGLogin">
  <div class="topWidth"></div>
  <div class="container">
    <div *ngIf="errorMessage!=''" class="alert alert-danger ">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success ">
      {{successMessage}}
    </div>
    <form  #profileForm="ngForm"  (ngSubmit)="updateProfile(profileForm)">
    <div class="mainBox row">
     <h3><a class="whiteLink mg-r-10" routerLink="/profile"><i class="fa fa-arrow-left"></i></a>  Edit Account Details</h3>
      <div class="col-2 image">
        <img  [src]="postLoginService.loggedUser?.full_profile_pic_url" class="rounded-circle" alt="">
        <input  type="file" hidden id="upload"  #upload (change)="fileChangeEvent($event)" />
        <div (click)="upload.click()"  class="yellowLink" >Upload Image</div>
        <input type="hidden" *ngIf="croppedImage!=''" name="profile_pic" [(ngModel)]="croppedImage">
      </div>
      <div class="col-10 row">
        <div class="col-6">
          <div class="inpBox">
            <label for="">Firstname</label>
            <ngx-skeleton-loader   *ngIf="postLoginService.loggedUser.first_name=='' ||postLoginService.loggedUser.first_name==undefined " animation="progress-dark" ></ngx-skeleton-loader>
            <input  [(ngModel)]="postLoginService.loggedUser.first_name" name="first_name" type="text" class="form-control">
          </div>

          <div class="inpBox">
            <label for="">Lastname</label>
            <ngx-skeleton-loader   *ngIf="postLoginService.loggedUser.last_name=='' ||postLoginService.loggedUser.last_name==undefined " animation="progress-dark" ></ngx-skeleton-loader>
            <input  [(ngModel)]="postLoginService.loggedUser.last_name" name="last_name" type="text" class="form-control">
          </div>

          <div class="inpBox">
            <label for="">Email</label>
            <ngx-skeleton-loader   *ngIf="postLoginService.loggedUser.last_name=='' ||postLoginService.loggedUser.last_name==undefined " animation="progress-dark" ></ngx-skeleton-loader>
            <input required [(ngModel)]="postLoginService.loggedUser.email" name="email"  type="email" class="form-control">
          </div>
          <div class="inpBox">
            <label for="">Phone</label>
            <div class="input-group mg-b-10">
              <div class="input-group-prepend darkInputBox mg-r-10">
                {{postLoginService.loggedUser.phone_area_code}} -
              </div>
            <ngx-skeleton-loader   *ngIf="postLoginService.loggedUser.last_name=='' ||postLoginService.loggedUser.last_name==undefined " animation="progress-dark" ></ngx-skeleton-loader>
              <input  [(ngModel)]="postLoginService.loggedUser.phone_number" name="phone_number"   type="text" class="form-control">
            </div>
          </div>
          <div class="inpBox">
            <label for="">Gender</label>
            <div class="custom-control custom-radio">
              <input type="radio" required [(ngModel)]="postLoginService.loggedUser.gender" name="gender"  id="customRadio1" value="male" class="custom-control-input" checked>
              <label class="custom-control-label" for="customRadio1">Male</label>
            </div>

            <div class="custom-control custom-radio">
              <input type="radio" required [(ngModel)]="postLoginService.loggedUser.gender" name="gender"  id="customRadio2" value="female" class="custom-control-input">
              <label class="custom-control-label" for="customRadio2">Female</label>
            </div>
          </div>
      </div>
      <div class="col-6">
        <div class="inpBox">
          <label for="">Conditions</label>
          <ngx-skeleton-loader   *ngIf="postLoginService.loggedUser.last_name=='' ||postLoginService.loggedUser.last_name==undefined " animation="progress-dark" ></ngx-skeleton-loader>
          <textarea rows="2" name="medical_conditions" [(ngModel)]="postLoginService.loggedUser.medical_conditions"  class="form-control">{{postLoginService.loggedUser.medical_conditions}}</textarea>
        </div>
        <div class="inpBox">
          <label for="">Notes</label>
          <ngx-skeleton-loader   *ngIf="postLoginService.loggedUser.last_name=='' ||postLoginService.loggedUser.last_name==undefined " animation="progress-dark" ></ngx-skeleton-loader>
          <textarea rows="2" name="notes" [(ngModel)]="postLoginService.loggedUser.notes"  class="form-control">{{postLoginService.loggedUser.notes}}</textarea>
        </div>
        <div class="btnBtm">
          <button [disabled]="!profileForm.valid" type="submit" class="btn btn-yellow btn-block">SAVE</button>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- Modal -->

<ng-template #content let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title tx-white" id="exampleModalLabel">Crop Image</h5>
        <button type="button" class="close " aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          format="png"
          (imageCropped)="imageCropped($event)"
          roundCropper='true'
          >
        </image-cropper>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-dark" data-dismiss="modal">Close</button>
        <button type="button" (click)="saveCropped(profileForm)" class="btn btn-yellow">Save changes</button>
      </div>
    </div>
</ng-template>
