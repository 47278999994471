import {   Component,   OnInit,  TemplateRef,  ViewChild} from '@angular/core';
import {  Router} from '@angular/router';
import {  NgxUiLoaderService  } from 'ngx-ui-loader';
import {  AuthGuardService  } from 'src/app/shared/auth-guard.service';
import {   PostloginServiceService } from 'src/app/shared/postlogin-service.service';
import * as braintree from 'braintree-web';
import {  NgbModal,  ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
var dropin = require('braintree-web-drop-in');
declare let fbq:Function;

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.css']
})
export class BookingSummaryComponent implements OnInit {
  @ViewChild("content") modalContent: TemplateRef < any > ;
  @ViewChild("cardsContent") cardsModal: TemplateRef < any > ;

  constructor(private modalService: NgbModal,
    public router: Router,
    public authGuard: AuthGuardService,
    public postLoginService: PostloginServiceService,
    private ngxService: NgxUiLoaderService) {
    this.authGuard.isTokenValid();
    if(this.postLoginService.bookingData.locationType=="GymLocation"){
      this.ngxService.start();
      this.postLoginService.getLocationFees(this.postLoginService.bookingData.trainerId,
        this.postLoginService.bookingData.addressLocationId,
        this.postLoginService.bookingData.locationType,this.postLoginService.bookingData.facilityMember).subscribe(
        res => {
          this.postLoginService.bookingData.facilityFee = Number(res['location_fee'].trainer_fee) +Number(res['location_fee'].client_fee) ;
          this.data = {
            mainCategoryId: this.postLoginService.bookingData.mainCategoryId,
            subCategoryId: this.postLoginService.bookingData.subCategoryId,
            addressLocationId: this.postLoginService.bookingData.addressLocationId,
            addressAreaId: this.postLoginService.bookingData.addressAreaId,
            selectedTime: this.postLoginService.bookingData.selectedTime,
            selectedDate: this.postLoginService.bookingData.selectedDate,
            short_address: this.postLoginService.bookingData.short_address,
            workoutName: this.postLoginService.bookingData.workoutName,
            locationType: this.postLoginService.bookingData.locationType,
            areaName: this.postLoginService.bookingData.areaName,
            trainerId: this.postLoginService.bookingData.trainerId,
            perSession: this.postLoginService.bookingData.perSession,
            noSessions: this.postLoginService.bookingData.noSessions,
            noParticipants: this.postLoginService.bookingData.noParticipants,
            trainerName: this.postLoginService.bookingData.trainerName,
            facilityFee: this.postLoginService.bookingData.facilityFee,
            coachFees: this.postLoginService.bookingData.coachFees,
            sessionLength: this.postLoginService.bookingData.sessionLength
          }
          this.totalCost = this.data.coachFees * this.data.noSessions + this.data.facilityFee;
          this.initialTotalCost=this.totalCost
          this.ngxService.stop();
        });
    }else{
      this.postLoginService.bookingData.facilityFee=0;
      this.data = {
        mainCategoryId: this.postLoginService.bookingData.mainCategoryId,
        subCategoryId: this.postLoginService.bookingData.subCategoryId,
        addressLocationId: this.postLoginService.bookingData.addressLocationId,
        addressAreaId: this.postLoginService.bookingData.addressAreaId,
        selectedTime: this.postLoginService.bookingData.selectedTime,
        selectedDate: this.postLoginService.bookingData.selectedDate,
        short_address: this.postLoginService.bookingData.short_address,
        workoutName: this.postLoginService.bookingData.workoutName,
        locationType: this.postLoginService.bookingData.locationType,
        areaName: this.postLoginService.bookingData.areaName,
        trainerId: this.postLoginService.bookingData.trainerId,
        perSession: this.postLoginService.bookingData.perSession,
        noSessions: this.postLoginService.bookingData.noSessions,
        noParticipants: this.postLoginService.bookingData.noParticipants,
        trainerName: this.postLoginService.bookingData.trainerName,
        facilityFee: this.postLoginService.bookingData.facilityFee,
        coachFees: this.postLoginService.bookingData.coachFees,
        sessionLength: this.postLoginService.bookingData.sessionLength
      }
      this.totalCost = this.data.coachFees * this.data.noSessions + this.data.facilityFee;
      this.initialTotalCost=this.totalCost
    }
  }
  data;
  totalCost;
  errorMessage = '';
  successMessage = '';
  summary;
  math = Math;
  hostedFieldsInstance: braintree.HostedFields;
  clientToken;
  ngOnInit(): void {
    if (this.postLoginService.bookingData.trainerName == '') {
      this.router.navigate(['showCoaches']);
    }
    this.ngxService.start();
    this.postLoginService.getBraintreeToken().subscribe(
      res => {
        this.clientToken = res['data'].client_token;
        this.ngxService.stop();
      });
  }


  walletCredits;
  showJoompaWalletBox = false;
  usedWalletCredits;
  initialTotalCost=0;

  loadWalletCredits() {
    this.totalCost=this.initialTotalCost
    this.ngxService.start();
    this.postLoginService.getJoompaPaymentMethods().subscribe(
      res => {
        this.walletCredits = res['data'].payment_methods;
        this.ngxService.stop();
      }
    );
  }
  makePaymentWithJoompa(){
    if(this.postLoginService.bookingData.bookingId!=""){
      this.ngxService.start();
      this.postLoginService.createBookingTrainingSession(
        this.postLoginService.bookingData.bookingId,
        this.postLoginService.bookingData.startTime,
        this.postLoginService.bookingData.endTime,
        this.postLoginService.bookingData.addressAreaId,
        this.postLoginService.bookingData.locationType,
        this.postLoginService.bookingData.addressLocationId,
        this.postLoginService.bookingData.facilityMember,
        this.usedWalletCredits,this.totalCost,0
      ).subscribe(
        res=>{
          this.modalService.dismissAll(this.modalContent)
          fbq('track', 'Purchase',{value: this.totalCost,currency: 'MYR'});
          this.router.navigate(['sessionSuccess']);
          this.ngxService.stop();
        },
        err=>{
          this.errorMessage=err.error.error;
          this.ngxService.stop();
          setTimeout(() => {
            this.errorMessage='';
          }, 3000);
        }
      );
    }
    else{
      this.ngxService.start();
      this.postLoginService.createNewBooking(this.usedWalletCredits, this.totalCost, 0,
        this.postLoginService.bookingData.noSessions, this.postLoginService.bookingData.noParticipants,
        this.postLoginService.bookingData.trainerId, this.postLoginService.bookingData.sessionLength, this.postLoginService.bookingData.subCategoryId
      ).subscribe(
        res => {
          this.modalService.dismissAll(this.modalContent)
          fbq('track', 'Purchase',{value: this.totalCost,currency: 'MYR'});
          this.router.navigate(['sessionSuccess']);
          this.ngxService.stop();
        },
        err => {
          this.errorMessage= err.error.error;
          setTimeout(() => {
            this.errorMessage= '';
          }, 3000);
          this.ngxService.stop();
        }
      );
    }
  }
  brainTreePaymentMethods;
  next() {
    if(this.usedWalletCredits==undefined){
      this.postLoginService.bookingData.usedWalletCredits=0;
    }else{
      this.postLoginService.bookingData.usedWalletCredits=this.usedWalletCredits;
    }
    this.postLoginService.bookingData.totalCost=this.totalCost;
    this.router.navigate(['makePayment'])
  }

  applyWalletCreds(){
    this.totalCost=this.initialTotalCost
    this.totalCost = this.totalCost - this.usedWalletCredits
  }

  buyPackageWithoutPayment(){
    this.ngxService.start();
    this.postLoginService.buyPackageOnlyWithOutSession(this.usedWalletCredits, this.totalCost, 0,
      this.postLoginService.bookingData.noSessions, this.postLoginService.bookingData.noParticipants,
      this.postLoginService.bookingData.trainerId, this.postLoginService.bookingData.sessionLength, this.postLoginService.bookingData.subCategoryId
    ).subscribe(
      res => {
        fbq('track', 'Purchase',{value: this.totalCost,currency: 'MYR'});
        this.router.navigate(['sessionSuccess']);
        this.ngxService.stop();
      },
      err => {
        this.errorMessage= err.error.error;
        setTimeout(() => {
          this.errorMessage= '';
        }, 3000);
        this.ngxService.stop();
      }
    );
  }
  makePaymentForPackagePurchase(){
    if(this.usedWalletCredits==undefined){
      this.postLoginService.bookingData.usedWalletCredits=0;
    }else{
      this.postLoginService.bookingData.usedWalletCredits=this.usedWalletCredits;
    }
    this.postLoginService.bookingData.totalCost=this.totalCost;
    this.router.navigate(['makePayment'])
  }
}
