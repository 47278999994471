<app-header></app-header>
<div class="fullBGLogin">
  <div class="topWidth">
  </div>
  <div class="container">
    <h1 class="headingH1">FAQ's</h1>
    <div class="mainBox row">
      <div class="col-12 leftSide" >
        <h3><a class="whiteLink mg-r-10" routerLink="/profile"><i class="fa fa-arrow-left"></i></a> Frequently Asked Questions</h3>
        <div  class="scrollbar locationDiv" id="style-9" >
          <div  *ngFor="let faq of faqs"  >
            <div class="achievements">
              <button class="btn btn-themeBG btn-block" data-toggle="collapse" [attr.href]="'#collapseExample'+faq.id" role="button"
                aria-expanded="false" aria-controls="collapseExample">{{faq.question}} <i class="fa fa-caret-down"> </i></button>
              <div class="botCol collapse mg-t-5" id="collapseExample{{faq.id}}">
                {{faq.answer}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
