import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService) {
     }
  userProfile;
  bookedTrainers=[];

  navigate(){
    this.router.navigate(['editProfile'])
  }
  ngOnInit(): void {
    this.authGuard.isTokenValid();
      this.ngxService.start();
      this.postLoginService.getBookedTrainers().subscribe(
        res=>{
          this.bookedTrainers=res['trainers'];
          this.postLoginService.getProfile().subscribe(
            res=>{
              this.userProfile=res['user'];
              this.postLoginService.loggedUser.last_name=res['user'].last_name
              this.postLoginService.loggedUser.first_name=res['user'].first_name
              this.postLoginService.loggedUser.email=res['user'].email
              this.postLoginService.loggedUser.phone_number=res['user'].phone_number
              this.postLoginService.loggedUser.medical_conditions=res['user'].medical_conditions
              this.postLoginService.loggedUser.notes=res['user'].notes
              this.postLoginService.loggedUser.gender=res['user'].gender
              this.postLoginService.loggedUser.id=res['user'].id
              this.postLoginService.loggedUser.auth_token=res['user'].auth_token
              this.postLoginService.loggedUser.full_profile_pic_url=res['user'].full_profile_pic_url
              this.postLoginService.loggedUser.phone_area_code=res['user'].phone_area_code
              this.ngxService.stop();
            });
        }
      );
  }

  openPackage(id,item){
    this.postLoginService.bookedSession=this.bookedTrainers[id];
    this.router.navigate(['/coachPackage/'+item])
  }
}
