<app-header></app-header>
<div class="fullBGLogin">
    <div class="container text-center">
        <h1 class="bookingHeading">Pick an activity</h1>
        <div *ngIf="errorMessage!=''" class="alert alert-danger ">
          {{errorMessage}}
        </div>
        <div *ngIf="successMessage!=''" class="alert alert-success ">
            {{successMessage}}
        </div>
        <div class="row" *ngIf="categories!==undefined">
          <div class="col-md-1 col-sm-1 col-2">
            <div *ngIf="categories?.length>3" class="arrow leftSide"><i class="fa fa-arrow-left"></i></div>
          </div>
          <div class="col-md-10 col-sm-10 col-8" [ngClass]="{'col-md-12 col-sm-10 ':categories?.length<=3==true}">
            <drag-scroll id="style-9" class="slider mg-t-30 pd-l-5 pd-r-5" >
                <ng-container  *ngFor="let item of categories;let i = index" >
                      <div class="card1" [ngClass]="{'rotate': item.sub_categories.length>1==true}">
                          <div class="side"  [style.background-image]="'url('+item.display_photo_1x.url+')'">
                              <div class="h2Height">
                                  <h2>{{item?.name}}</h2>
                                  <button *ngIf="item.sub_categories.length==1" type="button" (click)="selectCategory(item.sub_categories[0].id,item.sub_categories[0].category_id,item.sub_categories[0].title_with_category,item.display_photo_3x.url)" class="btn btn-yellow">SELECT</button>
                                  <button *ngIf="item.sub_categories.length>1" type="button" class="btn btn-yellow">SELECT</button>
                              </div>
                          </div>
                          <div *ngIf="item.sub_categories.length>1" class="side back">
                              <h4>What are the goals you want to archive?</h4>
                              <ul class="list-group ">
                                  <li *ngFor="let cat of item.sub_categories" (click)="selectCategory(cat.id,cat.category_id,cat.title_with_category,item.display_photo_3x.url)" class="list-group-item d-flex align-items-center">
                                      <div class="text-left" >
                                      <h6 >{{cat.title}}</h6>
                                      <p >{{cat.explanation}}</p>
                                      </div>
                                      <span><i class="fa fa-chevron-right"></i></span>
                                  </li>
                              </ul>
                          </div>
                      </div>
                </ng-container>
          </drag-scroll>
          </div>
          <div class="col-md-1 col-sm-1 col-2">
            <div *ngIf="categories?.length>3" class="arrow rightSide"><i class="fa fa-arrow-right"></i></div>
          </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content darkModal">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Rate your last session</h5>
        <button (click)="dismissReview()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="errorMessage!=''" class="alert alert-danger ">
          {{errorMessage}}
        </div>
        <div *ngIf="successMessage!=''" class="alert alert-success ">
            {{successMessage}}
          </div>
        <div class="head">Rate you last session</div>
        <div class="smallFoot">With {{pendingReview?.trainer_name}}</div>
        <div class="buttns">
          <div class="bt"  [ngClass]="{'selected': selectedThumb == true}"  (click)="selectThumb(true)">
            <i class="fa  fa-thumbs-up"></i>
          </div>
          <div class="bt"  [ngClass]="{'selected': selectedThumb == false}"  (click)="selectThumb(false)">
            <i class="fa  fa-thumbs-down fa-flip-horizontal" ></i>
          </div>
        </div>
        <div class="divider"></div>
        <div  >
            <div class="head1">
              What did you like?
            </div>
            <div class="options">
              <div  [ngClass]="{'selected': selectedOption[0] == true}" (click)="selectOption(0)" class="option">ENCOURAGEMENT/MOTIVATION</div>
              <div  [ngClass]="{'selected': selectedOption[1] == true}" (click)="selectOption(1)"  class="option">PUNCTUALITY</div>
              <div [ngClass]="{'selected': selectedOption[2] == true}" (click)="selectOption(2)"  class="option">COMMUNICATION</div>
              <div [ngClass]="{'selected': selectedOption[3] == true}" (click)="selectOption(3)"  class="option">FRIENDLINESS/POLITENESS</div>
              <div [ngClass]="{'selected': selectedOption[4] == true}" (click)="selectOption(4)"  class="option">INJURY MANAGEMENT</div>
              <div [ngClass]="{'selected': selectedOption[5] == true}" (click)="selectOption(5)"  class="option">ATTENTION TO TECHNIQUE</div>
            </div>
        </div>
        <div class="review">
          <div class="inpBox">
            <label for="">Write a review</label>
            <textarea rows="2" placeholder="Tell us more about your experience!" [(ngModel)]="comment" class="form-control"></textarea>
          </div>
          <div class="col-12 btnBtm">
            <button [disabled]="enableBtn==false" (click)="submitReview()" class="btn btn-yellow btn-block">SUBMIT</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">

      </div>
    </div>
  </div>
</div>
