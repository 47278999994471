import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';
declare var $:any;

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  @ViewChild("content") modalContent: TemplateRef < any > ;

  constructor(private modalService: NgbModal,
    public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService,
    private storage:LocalStorageService) {
      this.authGuard.isTokenValid();
      if(this.postLoginService.loggedUser.first_name==''){
        this.ngxService.start();
        this.postLoginService.getProfile().subscribe(
          res=>{
            this.userProfile=res['user']
            this.postLoginService.loggedUser.last_name=res['user'].last_name
            this.postLoginService.loggedUser.first_name=res['user'].first_name
            this.postLoginService.loggedUser.email=res['user'].email
            this.postLoginService.loggedUser.phone_number=res['user'].phone_number
            this.postLoginService.loggedUser.medical_conditions=res['user'].medical_conditions
            this.postLoginService.loggedUser.notes=res['user'].notes
            this.postLoginService.loggedUser.phone_area_code=res['user'].phone_area_code
            this.postLoginService.loggedUser.gender=res['user'].gender
            this.postLoginService.loggedUser.id=res['user'].id
            this.postLoginService.loggedUser.auth_token=res['user'].auth_token
            this.postLoginService.loggedUser.full_profile_pic_url=res['user'].full_profile_pic_url
            this.ngxService.stop();
          });
      }
     }
  fetchedImage;
  userProfile;
  successMessage='';
  errorMessage='';
  userImage;
  ngOnInit(): void {

  }
  updateProfile(form:NgForm){
    this.ngxService.start();
    this.postLoginService.updateUserProfile(form.value).subscribe(
      res=>{
        this.userProfile=res['user'];
        this.storage.clear('userData');
        var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
        this.storage.store('userData',data);
        this.successMessage='Updated successfully!';
        this.ngxService.stop();
        setTimeout(() => {
          this.successMessage='';
          this.router.navigate(['profile'])
        }, 3000);
      },
      err=>{
        this.errorMessage=err.error.error;
        this.ngxService.stop();
        setTimeout(() => {
          this.errorMessage='';
        }, 3000);
      }
    );
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    let allowFormats = ['jpg','pdf','jpeg']
    let maxFileSize = 1024 * 1024 * 4;
    let file = <File> event.target.files[0];
    let name = file.name.split('.')[1].toLocaleLowerCase();
    let size  = file.size;
    if(allowFormats.indexOf(name) > -1){
      if(size<maxFileSize){
        this.croppedImage=null;
        this.imageChangedEvent = event;
        this.modalService.open(this.modalContent, {
          windowClass: 'dark-modal'
        })
      }else{
        this.errorMessage="Max file size is 4MB.";
        event.target.value = ''
        setTimeout(() => {
          this.errorMessage='';
        }, 6000);
      }
    }
    else{
      this.errorMessage="Invalid file-type. Only JPG,PNG,PDF file-types are allowed";
      event.target.value = ''
      setTimeout(() => {
        this.errorMessage='';
      }, 6000);
    }
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.postLoginService.loggedUser.full_profile_pic_url= this.croppedImage;
  }
  imageLoaded(image: HTMLImageElement) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }
  saveCropped(form:NgForm){
    this.userImage = this.croppedImage;
    this.modalService.dismissAll(this.modalContent)
    this.ngxService.start();
    this.postLoginService.updateUserProfile(form.value).subscribe(
      res=>{
        this.userProfile=res['user'];
        this.storage.clear('userData');
        var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
        this.storage.store('userData',data);
        this.successMessage='Updated successfully!';
        this.ngxService.stop();
        setTimeout(() => {
          this.successMessage='';
          this.router.navigate(['profile'])
        }, 3000);
        this.ngxService.stop();
      },
      err=>{
        this.errorMessage=err.error.error;
        this.ngxService.stop();
        setTimeout(() => {
          this.errorMessage='';
        }, 3000);
      }
    );
  }
}
