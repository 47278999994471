import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import { environment} from '../../environments/environment'
import { HttpClient,HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  auth_token = null;
  constructor(private http : HttpClient,
    public router: Router,
    private storage:LocalStorageService) {
        this.auth_token = this.storage.retrieve('auth_token');
    }

    public isSessionAvailable(){
      if(this.auth_token!=null){
        return true;
      }
      else{
        return false;
      }
    }
    public isTokenValid() {
      this.auth_token = this.storage.retrieve('auth_token');
      var refreshTime = this.storage.retrieve('refreshTime');
      var currTime = new Date()
      if(currTime>refreshTime){
        if(this.auth_token!=null){
          var header = {
            headers: new HttpHeaders()
              .set('Authorization', this.auth_token)
          }
           this.http.get(environment.BaseURL+"/users/profile",header).subscribe(
             res=>{
                return true;
             },
            err=>{
              this.storage.clear('auth_token');
              this.storage.clear('userData');
                this.router.navigate(['login'],{queryParams:{reAuth:'2'}});
            }
           );
        }
        else{
          this.storage.clear('auth_token');
          this.storage.clear('userData');
          this.router.navigate(['login'],{queryParams:{reAuth:'1'}});
        }
      }
    }

   public getTokeFromLocalStorage(){
      return this.storage.retrieve('auth_token');
    }

}
