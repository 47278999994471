import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PreloginServiceService } from 'src/app/shared/prelogin-service.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import {LocalStorageService} from 'ngx-webstorage';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { isToken } from 'typescript';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers : []
})
export class LoginComponent implements OnInit {

  successMessage ='';
  errorMessage ='';
  fbLoginData = {
    access_token:null,
    uid:null,
    provider:null
  };

  reAuthMessage=[
    "Please login to continue",
    "Session expired! Please login again."
  ]

  genericErrorMessage = '';
  genericSuccessMessage ='';
  constructor(public router:Router,
    public loginService:PreloginServiceService,
    private ngxService: NgxUiLoaderService,
    private authService: SocialAuthService,
    private storage:LocalStorageService,
    private route: ActivatedRoute,
    public authGuard : AuthGuardService,
    public preloginService:PreloginServiceService) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams['reAuth']) {
      this.route.queryParams.subscribe(params => {
        this.genericErrorMessage = this.reAuthMessage[params['reAuth']];
        setTimeout(()=>{
          this.genericErrorMessage = '';
     }, 5000);
    });
    }
    if (this.route.snapshot.queryParams['logout']) {
      this.route.queryParams.subscribe(params => {
        this.genericSuccessMessage = 'Logout Success!';
        setTimeout(()=>{
          this.genericSuccessMessage = '';
     }, 5000);
    });
    }else{
      if(this.authGuard.isSessionAvailable()){
        this.router.navigate(['booking']);
      }
    }

  }

  signInWithFB(): void {
    this.ngxService.start();
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      x => {
        this.fbLoginData.access_token = x.authToken;
        this.fbLoginData.uid = x.id;
        this.fbLoginData.provider = x.provider;
        this.loginService.omniauth(this.fbLoginData).subscribe(
          res=>{
            if(res['user'].verified==false){
              this.preloginService.loggedUser.auth_token=res['user'].auth_token;
              this.router.navigate(['phoneNumber']);
              this.ngxService.stop();
            }else{
              this.storage.store('auth_token', res['user'].auth_token);
              var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
              this.storage.store('userData',data);
              var dt = new Date();
              dt.setHours( dt.getHours() + 2 );
              this.storage.store('refreshTime',dt)
              this.router.navigate(['booking']);
              this.ngxService.stop();
            }
          },
          err=>{
            this.errorMessage = err.error.error;
            this.ngxService.stop();
          }
        );
      });
    }

  onSubmit(form:NgForm){
    this.ngxService.start();
    this.loginService.loginUser(form.value).subscribe(
      res=>{
        if(res['user'].verified==false){
          this.preloginService.loggedUser.auth_token=res['user'].auth_token;
          this.router.navigate(['phoneNumber']);
          this.ngxService.stop();
        }else{
          this.storage.store('auth_token', res['user'].auth_token);
          var data = {"name":res['user'].first_name+" "+res['user'].last_name,"image":res['user'].full_profile_pic_url}
          this.storage.store('userData',data);
          var dt = new Date();
          dt.setHours( dt.getHours() + 2 );
          this.storage.store('refreshTime',dt)
          this.router.navigate(['booking']);
          this.ngxService.stop();
        }
      },
      err=>{
        this.errorMessage = err.error.error;
        this.ngxService.stop();
      }
    )
  }

  goToLink(url: string){
    window.open(url,"_self");
}
}
