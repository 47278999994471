<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand" href="/booking"> <img src="../../../assets/img/joompaLogo.png" class="profile-picture"/></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

    </ul>
    <div class="navLiR">
      <ul>
          <a routerLink="/profile">
            <li *ngIf="userData!=undefined"><img [src]="userData?.image" class="profile-pictureDP"/></li>
            <li *ngIf="userData==undefined"> <ngx-skeleton-loader appearance="circle"></ngx-skeleton-loader></li>
          </a>
          <a routerLink="/profile">
            <li  *ngIf="userData!=undefined">{{userData?.name}}</li>
            <li *ngIf="userData==undefined"> <ngx-skeleton-loader ></ngx-skeleton-loader></li>
          </a>
      </ul>
  </div>
  </div>
</nav>
