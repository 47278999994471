<app-header></app-header>
<div class="fullBGLogin">
  <div class="halfBG" [style.background-image]="'url('+postLoginService.bookingData.workoutImageURL+')'" ></div>
  <div class="container text-center">
    <h1 class="headMargin"></h1>
    <div *ngIf="errorMessage!=''" class="alert alert-danger ">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success ">
      {{successMessage}}
    </div>

    <div  class="row  no-gutters " *ngIf="this.postLoginService.bookingData.flow=='Normal'">
      <div *ngIf="this.postLoginService.bookingData!=undefined" class="col-md-6 col-12 summaryBox">
        <p class="fadedTxt">ORDER SUMMARY</p>
        <div >
          <table class="table table-borderless overviewTable">
            <tr>
              <td class="lt">Number of sessions</td>
              <td class="rt">{{this.postLoginService.bookingData.noSessions}}</td>
            </tr>
            <tr>
              <td class="lt">Participants</td>
              <td class="rt">{{this.postLoginService.bookingData.noParticipants}}</td>
            </tr>
            <tr>
              <td class="lt">Date of Session</td>
              <td class="rt">{{this.postLoginService.bookingData.selectedDate}}</td>
            </tr>
            <tr>
              <td class="lt">Time of Session</td>
              <td class="rt">{{this.postLoginService.bookingData.selectedTimeNew}}</td>
            </tr>
            <tr>
              <td class="lt">Coach</td>
              <td class="rt">{{this.postLoginService.bookingData.trainerName}}</td>
            </tr>
          </table>
        </div>
        <div class="divider"></div>
        <p class="fadedTxt">COST</p>
        <!-- <div class="row cardInfo">
          <div class="col-8">
            <div class="input-group">
              <input type="text" class="form-control darkInputBox" placeholder="Enter promo code" aria-label="Enter promo code" aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-light btn-dark" type="button" id="button-addon2">Apply</button>
              </div>
            </div>
          </div>
          <div class="col-4">
            <img src="../../../assets/img/coupon.png"> My Codes <i class="fa fa-caret-down"></i>
          </div>
        </div> -->
        <div class="">
            <table class="table table-borderless overviewTable">
              <tr>
                <td class="lt">Coach Fee</td>
                <td class="rt">RM {{this.postLoginService.bookingData.perSession * this.postLoginService.bookingData.noSessions}}</td>
              </tr>
              <tr>
                <td class="lt">Facility Fee</td>
                <td class="rt">RM {{this.postLoginService.bookingData.facilityFee}}</td>
              </tr>
              <tr>
                <td class="lt">Discount</td>
                <td class="rt">RM 0</td>
              </tr>
              <tr class="walletBox" *ngIf="showJoompaWalletBox">
               <td colspan="2">
                <div *ngIf="walletCredits!=undefined">
                  <div class="tbLeft">
                     <span class="yellowColor">{{walletCredits[walletCredits.length-1].description}}</span>
                  </div>
                  <div class="form-group wallet">
                    Use <input knifeOnlyNumbers type="text" placeholder="Only Numbers"  #usedCreds="ngModel" [(ngModel)]="usedWalletCredits"  class="form-control form-control-lg text-center darkInputBox" placeholder="Enter credits to use"  required>
                    <button [disabled]="usedWalletCredits>initialTotalCost || usedWalletCredits<=0" (click)="applyWalletCreds()" class="btn btn-dark">Apply</button>
                  </div>
               </div>
               </td>
               <div *ngIf="walletCredits==undefined">
                  <td class="lt lta">Available  <ngx-skeleton-loader   animation="progress-dark" ></ngx-skeleton-loader></td>
                  <td class="rt rta">
                    <div class="form-group wallet">
                    -  <ngx-skeleton-loader   animation="progress-dark" ></ngx-skeleton-loader>
                    </div>
                  </td>
               </div>
              </tr>
              <tr>
                <td colspan="2" style="width:100%">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox ">
                      <input  (change)="loadWalletCredits()" #joompaCreds="ngModel" [(ngModel)]="showJoompaWalletBox"  type="checkbox" class="custom-control-input" id="customCheck1">
                      <label class="custom-control-label" for="customCheck1">USE JOOMPA WALLET CREDITS</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="lt yellowColor">Total Cost</td>
                <td class="rt yellowColor">RM {{totalCost}}</td>
              </tr>
            </table>
          </div>

          <div class="divider"></div>
          <p class="tnc">By purchasing this session you’re agreeing to our <span>cancellation policy</span></p>
          <div class="mg-t-20">
            <button *ngIf="this.totalCost==0" (click)="makePaymentWithJoompa()" type="button" class="btn btn-yellow btn-block">BOOK SESSION</button>
            <button *ngIf="this.totalCost!=0" (click)="next()" type="button" class="btn btn-yellow btn-block">PAY NOW</button>
         </div>
        </div>
    </div>
    <div  class="row  no-gutters " *ngIf="this.postLoginService.bookingData.flow=='bookPackageForCoach'">
      <div *ngIf="this.postLoginService.bookingData!=undefined" class="col-md-6 col-12 summaryBox">
        <p class="fadedTxt">ORDER SUMMARY</p>
        <div >
          <table class="table table-borderless overviewTable">
            <tr>
              <td class="lt">Number of sessions</td>
              <td class="rt">{{this.postLoginService.bookingData.noSessions}}</td>
            </tr>
            <tr>
              <td class="lt">Participants</td>
              <td class="rt">{{this.postLoginService.bookingData.noParticipants}}</td>
            </tr>
            <tr>
              <td class="lt">Coach</td>
              <td class="rt">{{this.postLoginService.bookingData.trainerName}}</td>
            </tr>
          </table>
        </div>
        <div class="divider"></div>
        <div class="">
            <table class="table table-borderless overviewTable">
              <tr class="walletBox" *ngIf="showJoompaWalletBox">
               <td colspan="2">
                <div *ngIf="walletCredits!=undefined">
                  <div class="tbLeft">
                     <span class="yellowColor">{{walletCredits[walletCredits.length-1].description}}</span>
                  </div>
                  <div class="form-group wallet">
                    Use <input knifeOnlyNumbers type="text" placeholder="Only Numbers"  #usedCreds="ngModel" [(ngModel)]="usedWalletCredits"  class="form-control form-control-lg text-center darkInputBox" placeholder="Enter credits to use"  required>
                    <button [disabled]="usedWalletCredits>initialTotalCost || usedWalletCredits<=0" (click)="applyWalletCreds()" class="btn btn-dark">Apply</button>
                  </div>
               </div>
               </td>
               <div *ngIf="walletCredits==undefined">
                  <td class="lt lta">Available  <ngx-skeleton-loader   animation="progress-dark" ></ngx-skeleton-loader></td>
                  <td class="rt rta">
                    <div class="form-group wallet">
                    -  <ngx-skeleton-loader   animation="progress-dark" ></ngx-skeleton-loader>
                    </div>
                  </td>
               </div>
              </tr>
              <tr>
                <td colspan="2" style="width:100%">
                  <div class="col-12">
                    <div class="custom-control custom-checkbox ">
                      <input  (change)="loadWalletCredits()" #joompaCreds="ngModel" [(ngModel)]="showJoompaWalletBox"  type="checkbox" class="custom-control-input" id="customCheck1">
                      <label class="custom-control-label" for="customCheck1">USE JOOMPA WALLET CREDITS</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="lt yellowColor">Total cost to pay </td>
                <td class="rt yellowColor">RM {{totalCost}}</td>
              </tr>
            </table>
          </div>

          <div class="divider"></div>
          <p class="tnc">By purchasing this session you’re agreeing to our <span>cancellation policy</span></p>
          <div class="mg-t-20">
            <button *ngIf="this.totalCost==0" (click)="buyPackageWithoutPayment()" type="button" class="btn btn-yellow btn-block">BOOK PACKAGE</button>
            <button *ngIf="this.totalCost!=0" (click)="makePaymentForPackagePurchase()" type="button" class="btn btn-yellow btn-block">PAY NOW</button>
         </div>
        </div>
        </div>
      </div>
    </div>
