import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';
declare var $:any;

@Component({
  selector: 'app-coach-package',
  templateUrl: './coach-package.component.html',
  styleUrls: ['./coach-package.component.css']
})
export class CoachPackageComponent implements OnInit {

  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute) {
      this.authGuard.isTokenValid();
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
      if(this.postLoginService.bookedSession==undefined){
        this.ngxService.start();
        this.postLoginService.getBookedTrainers().subscribe(
          res=>{
            this.coach = res['trainers'].find(item => {
              return item.id == this.id;
            });
            this.ngxService.stop();
          }
        );
      }else{
        this.coach=this.postLoginService.bookedSession;
      }
    }

  coach;
  id;
  selectedBtn=[true,false];
  selectedItem;
  selectedSession;
  errorMessage='';
  successMessage='';
  usedSlot;
  nowTime=new Date();
  selectedDetailsBtn=[true,false]
  ngOnInit(): void {

  }

  convertTimeStartAndEnd(start_time,end_time){
    var date = new Date(start_time)
    var dateKey = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var hrs = hours<10 ? '0'+hours : hours;
    var min = minutes < 10 ? '0'+minutes : minutes;
    var timeKey1 = hrs + ':' + min + ' ' + ampm;
    var date = new Date(end_time)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var hrs = hours<10 ? '0'+hours : hours;
    var min = minutes < 10 ? '0'+minutes : minutes;
    var timeKey2 = hrs + ':' + min + ' ' + ampm;
    var timeSlot = String(dateKey+", "+timeKey1+" - "+timeKey2)
    return timeSlot;
  }

  show(id){
    for (let index = 0; index < this.selectedBtn.length; index++) {
      this.selectedBtn[index]=false;
    }
    this.selectedBtn[id]=true;
  }

  listClick(event, id) {
    this.usedSlot=undefined;
    this.selectedSession= this.coach.bookings.find(item => {
      return item.id == id;
    });
    this.selectedItem = id;
    window.setTimeout(() => {
      if ($('.rightSide').length > 0) {
        $('html, body').animate({
          scrollTop: $('.rightSide').offset().top
        }, 500);
      }
    }, (250));
  }

  bookNow(id){
    this.postLoginService.bookingData.bookingId=id;
    this.selectedSession= this.coach.bookings.find(item => {
      return item.id == id;
    });
    this.postLoginService.bookingData.coachFees = 0;
    this.postLoginService.bookingData.subCategoryId=this.selectedSession.sub_category.id;
    this.postLoginService.bookingData.workoutName=this.selectedSession.sub_category.title_with_category;
    this.postLoginService.bookingData.mainCategoryId=this.selectedSession.sub_category.category_id;
    this.postLoginService.bookingData.trainerId=this.coach.id;
    this.postLoginService.bookingData.trainerName=this.coach.first_name+" "+this.coach.last_name;
    this.router.navigate(['location']);
  }

  cancelBooking(bookingId,id){
    this.ngxService.start();
    this.postLoginService.cancelBooking(id).subscribe(
      res=>{
        this.postLoginService.postCancelBooking(id,res['cancellation_contract'].percentage).subscribe(
          res=>{
            this.postLoginService.getBookedTrainers().subscribe(
              res=>{
                this.coach = res['trainers'].find(item => {
                  return item.id == this.id;
                });
                this.selectedSession= this.coach.bookings.find(item => {
                  return item.id == bookingId;
                });
                 this.successMessage="Booking slot cancelled successfully!";
                this.ngxService.stop();
                this.usedSlot=undefined;
                setTimeout(() => {
                  this.successMessage='';
                }, 3000);
              },
              err=>{
                this.errorMessage=err.error.error;
                this.ngxService.stop();
                setTimeout(() => {
                  this.errorMessage='';
                }, 3000);
              }
            );
          }
        );
      },
      err=>{
        this.errorMessage=err.error.error;
        this.ngxService.stop();
        setTimeout(() => {
          this.errorMessage='';
        }, 3000);
      }
    );
  }

  counter(i: number) {
    return new Array(i);
}

view(id){
  this.usedSlot= this.selectedSession.training_sessions.find(item => {
    return item.id == id;
  });
  window.setTimeout(() => {
    if ($('.rightSide1').length > 0) {
      $('html, body').animate({
        scrollTop: $('.rightSide1').offset().top
      }, 500);
    }
  }, (250));
}

checkIfCompleted(item){
  return this.nowTime > new Date(item.end_time)
}

selectDetails(id){
  for (let index = 0; index < this.selectedDetailsBtn.length; index++) {
    this.selectedDetailsBtn[index]=false;
  }
  this.selectedDetailsBtn[id]=true;
}
}
