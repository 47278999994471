import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';
import { DatePipe } from '@angular/common';
import { NgbDate, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
declare let fbq:Function;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbDatepickerConfig]
})
export class CalendarComponent implements OnInit {

  selectedDate='';
  selectedTime='Any Time';
  errorMessage='';
  successMessage='';
  dates;
  formattedDates={};
  startAt=new Date();
  endAt=new Date((new Date()).getTime() + (60 * 86400000)) ;
  timeForSelectedDates=[];
  markDisabled;
  isDateSelected='';
  isTimeSelected='';
  selectedTimeSlot='';
  sessionLength=60;
  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService,
    private datePipe: DatePipe,
    config: NgbDatepickerConfig) {
      this.authGuard.isTokenValid();
      if(this.postLoginService.bookingData.addressLocationId==''){
        this.router.navigate(['location']);
      }
      else{
        if(this.postLoginService.bookingData.bookingId!=''){
          this.ngxService.start();
          this.postLoginService.getBookingTimeSlots(this.postLoginService.bookingData.bookingId,
            this.postLoginService.bookingData.locationType,
            this.postLoginService.bookingData.addressLocationId).subscribe(
            res=>{
              this.dates=res['time_slots'];
              this.ngxService.stop();
              var data = this.convertTimeSlotDates(this.dates);
              this.formattedDates=data[0];
              this.updateDisabledDates();
            },
            err=>{
              let navigationExtras: NavigationExtras = {
                state: {
                  existingBookingError: err.error.error
                }
              };
              this.router.navigate(['location'],navigationExtras);
              this.ngxService.stop();
            }
          );
        }
        else{
          this.ngxService.start();
          this.postLoginService.getAvailableDates(this.postLoginService.bookingData.subCategoryId,
            this.postLoginService.bookingData.addressAreaId,
            this.postLoginService.bookingData.locationType,
            this.postLoginService.bookingData.addressLocationId).subscribe(
            res=>{
              this.dates=res['data'].dates;
              if(this.dates.length==0){
                let navigationExtras: NavigationExtras = {
                  state: {
                    noDates: true
                  }
                };
                this.router.navigate(['location'],navigationExtras);
              }
              this.ngxService.stop();
              this.convertDate(this.dates);
              this.updateDisabledDates();
            }
          );
        }
      }
    }

    monthRefresh(){
      this.updateDisabledDates();
    }

    increment(){
        this.sessionLength+=15;
    }

    decrement(){
        this.sessionLength-=15;
    }

    updateDisabledDates() {
      this.markDisabled = (date: NgbDateStruct) => {
        const d = new Date(date.year, date.month - 1, date.day);
        if(this.formattedDates[this.formatDate(d)] == undefined  )
          return true
      };
    }

  ngOnInit(): void {
  }

  convertTimeSlotDates(datesResponse){
   var endAt=new Date((new Date()).getTime() + (60 * 86400000)) ;
   var formattedDates=[];
    Object.keys(datesResponse).forEach(function(key) {
      var date = new Date(key)
      var dateKey = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
      if(endAt.getTime() < date.getTime() ){
        endAt=date
      }
      Object.keys(datesResponse[key]).forEach(function(key1) {

        datesResponse[key][key1].forEach(function(key2) {
          var date = new Date(key2.start_time)
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12;
          var hrs = hours<10 ? '0'+hours : hours;
          var min = minutes < 10 ? '0'+minutes : minutes;
          var timeKey1 = hrs + ':' + min + ' ' + ampm;
          var date = new Date(key2.end_time)
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12;
          var hrs = hours<10 ? '0'+hours : hours;
          var min = minutes < 10 ? '0'+minutes : minutes;
          var timeKey2 = hrs + ':' + min + ' ' + ampm;
          var timeSlot = String(timeKey1+" - "+timeKey2)
          var pushData =[timeSlot,key2.start_time,key2.end_time]
          if(dateKey in formattedDates ){
            if(key1 in formattedDates[dateKey] ){
              formattedDates[dateKey][key1].push(pushData)
            }else{
              formattedDates[dateKey][key1]=[];
              formattedDates[dateKey][key1].push(pushData)
            }
          }else{
            formattedDates[dateKey]=[];
            formattedDates[dateKey][key1]=[];
            formattedDates[dateKey][key1].push(pushData)
          }
        });
      });
    });
    return ([formattedDates,endAt])
  }

  convertDate(datesResponse){
    datesResponse.forEach(dateObj => {
      var date = new Date(dateObj)
      var dateKey = date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      var hrs = hours<10 ? '0'+hours : hours;
      var min = minutes < 10 ? '0'+minutes : minutes;
      var timeKey = hrs + ':' + min + ' ' + ampm;
      if(this.endAt.getTime() < date.getTime() ){
        this.endAt=date
      }
      if(dateKey in this.formattedDates ){
        this.formattedDates[dateKey].push(timeKey)
      }else{
        this.formattedDates[dateKey]=[];
        this.formattedDates[dateKey].push(timeKey)
      }
    });
  }
  onDateSelected(date){
    this.selectedTime='Any Time'
    var dateKey = date.year+'-' + date.month + '-'+date.day;
    this.timeForSelectedDates = this.formattedDates[dateKey];
    this.isDateSelected=dateKey;
    this.selectedDate=dateKey
  }

  timeSelected(time){
    this.isTimeSelected=time;
  }

  formatDate(date){
    return date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate();
  }

  transformDate(date) {
    return date.day+"-"+date.month+"-"+date.year;
  }

  next(){
    if(this.selectedTime!='' && this.selectedDate!=''){
      this.postLoginService.bookingData.selectedTime=this.selectedTime;
      this.postLoginService.bookingData.sessionLength=this.sessionLength;
      this.postLoginService.bookingData.selectedDate=this.transformDate(this.selectedDate);
      this.router.navigate(['showCoaches']);
    }
    else{
      this.errorMessage='Please select valid date and time slot.';
      setTimeout(() => {
        this.errorMessage='';
      }, 3000);
    }
  }
  toggleFacilityMember(){
    if(this.postLoginService.bookingData.facilityMember==true)
      this.postLoginService.bookingData.facilityMember=false
    else
      this.postLoginService.bookingData.facilityMember=true
  }

  nextToBookingSummary(){
    if(this.isDateSelected!='' && this.selectedTimeSlot!=''){
        this.postLoginService.bookingData.sessionLength=this.sessionLength;
        this.postLoginService.bookingData.selectedTime=this.isTimeSelected;
        if(this.postLoginService.bookingData.locationType=="GymLocation"){
        this.postLoginService.bookingData.selectedDate=this.isDateSelected;
        this.postLoginService.bookingData.noSessions=1;
        this.postLoginService.bookingData.noParticipants=1;
        this.postLoginService.bookingData.startTime= this.formattedDates[this.isDateSelected][this.isTimeSelected][0][1];
        this.postLoginService.bookingData.endTime= this.formattedDates[this.isDateSelected][this.isTimeSelected][0][2];
        this.router.navigate(['bookingSummary']);
      }else{
      this.postLoginService.bookingData.selectedDate=this.transformDate(this.isDateSelected);
        this.ngxService.start();
        this.postLoginService.createBookingTrainingSession(
          this.postLoginService.bookingData.bookingId,
          this.formattedDates[this.isDateSelected][this.isTimeSelected][0][1],
          this.formattedDates[this.isDateSelected][this.isTimeSelected][0][2],
          this.postLoginService.bookingData.addressAreaId,
          this.postLoginService.bookingData.locationType,
          this.postLoginService.bookingData.addressLocationId,
          this.postLoginService.bookingData.facilityMember
        ).subscribe(
          res=>{
            fbq('track', 'Purchase',{value: 0,currency: 'MYR'});
            this.router.navigate(['sessionSuccess']);
            this.ngxService.stop();
          },
          err=>{
            this.errorMessage=err.error.error;
            this.ngxService.stop();
            setTimeout(() => {
              this.errorMessage='';
            }, 3000);
          }
        );
      }
    }
    else{
      this.errorMessage='Please select valid date and time slot.';
      setTimeout(() => {
        this.errorMessage='';
      }, 3000);
    }
  }
}
