import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PreloginServiceService } from 'src/app/shared/prelogin-service.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css']
})
export class PhoneNumberComponent implements OnInit {

  successMessage ='';
  errorMessage ='';
  countryCode="+60";
  constructor(public router:Router,
    public preloginService:PreloginServiceService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    if(this.preloginService.loggedUser.auth_token==''){
      this.router.navigate(['signUp'])
    }
    this.countryCode="+60"
  }
  sendOTP(form:NgForm){
    this.ngxService.start();
    this.preloginService.requestOTP(form.value).subscribe(
      res=>{
        let navigationExtras: NavigationExtras = {
          state: {
            phone: form.value.phone_number,
            countryCode: form.value.country_code
          }
        };
        this.router.navigate(['verifyOTP'],navigationExtras);
        this.ngxService.stop();
      },
      err=>{
         this.errorMessage = err.error.error;
        this.ngxService.stop();
      }
    )
  }
}
