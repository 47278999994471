<app-header></app-header>
<div class="fullBGLogin">
  <div class="halfBG" [style.background-image]="'url('+postLoginService.bookingData.workoutImageURL+')'"></div>
  <div class="container text-center">
    <h1 class="bookingHeading">{{this.postLoginService.bookingData.workoutName}}</h1>
    <div *ngIf="errorMessage!=''" class="alert alert-danger ">
      {{errorMessage}}
    </div>
    <div *ngIf="successMessage!=''" class="alert alert-success ">
      {{successMessage}}
    </div>

    <div  *ngIf="coachProfile!=undefined" class="row  no-gutters  mainBox">
      <div class="col-md-12 pd-20 bd headFont">
        New Package with
         <ngx-skeleton-loader *ngIf="coachProfile==undefined"  animation="progress-dark" ></ngx-skeleton-loader>
         <ng-container *ngIf="coachProfile!=undefined">{{coachProfile.first_name}}</ng-container>
      </div>
      <div class="col-md-12 bd pd-t-20">
        <div class="mapBox">
          <div class="rightHeadDiv row">
            <div class="col-md-6 col-6">
              <p>Category </p>
            </div>
            <div class="col-md-6 col-6 timeText">
              <select [(ngModel)]="selectedCategory" class="custom-select darkInputBox">
                <option  selected disabled value=''>Select a category</option>
                <ng-container *ngFor="let category of coachProfile.sub_categories" >
                  <option  value='{{category.id}}'>{{category.title}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="rightHeadDiv row" *ngIf="this.postLoginService.bookingData.bookingId==''">
            <div class="col-7 col-md-6">
              <p class="head">Session length</p>
            </div>
            <div class="col-3 text-right">
              {{sessionLength}} min
            </div>
            <div class="col-3 counterHead">
              <button [disabled]="sessionLength==30" class="btn btn-light btn-icon circleBttn" (click)="decrementSession()"
                href="#"><i class="fa fa-minus"></i></button>
              <button [disabled]="sessionLength>=120" class="btn btn-light btn-icon circleBttn" (click)="incrementSession()"
                href="#"><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <div class="rightHeadDiv row" *ngIf="this.postLoginService.bookingData.bookingId==''">
            <div class="col-7 col-md-6">
              <p class="head">Number of sessions</p>
            </div>
            <div class="col-3 text-right">
              {{noSessions}}
            </div>
            <div class="col-3 counterHead">
              <button [disabled]="noSessions==1" class="btn btn-light btn-icon circleBttn" (click)="decrement('session')" ><i class="fa fa-minus"></i></button>
                <button [disabled]="noSessions>=10" class="btn btn-light btn-icon circleBttn" (click)="increment('session')" ><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <div class="rightHeadDiv row" *ngIf="this.postLoginService.bookingData.bookingId==''">
            <div class="col-7 col-md-6">
              <p class="head">Number of participants</p>
            </div>
            <div class="col-3  text-right">
              {{noParticipants}}
            </div>
            <div class="col-3 counterHead">
              <button [disabled]="noParticipants==1"  class="btn btn-light btn-icon circleBttn" (click)="decrement('participants')" ><i class="fa fa-minus"></i></button>
              <button  [disabled]="noParticipants>=5" class="btn btn-light btn-icon circleBttn" (click)="increment('participants')" ><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <div class="col-md-12 mgTop">
            <button (click)="next()" [disabled]="selectedCategory==''" type="button" class="btn btn-yellow btn-block">NEXT</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
