import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';
declare var $: any;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  categories;
  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService) { }


  pendingReview;
  selectedThumb;
  comment='';
  selectedOption=[false,false,false,false,false,false];
  successMessage='';
  errorMessage='';
  enableBtn=false;
  ngOnInit(): void {
    this.authGuard.isTokenValid();
    this.ngxService.start();
    this.postLoginService.getCategories().subscribe(
      res=>{
        this.categories =  res;
        // let o = this.categories[ this.categories.findIndex(o => o.id === 5)]
        // let b = this.categories[0]
        // this.categories[1] =  b
        // this.categories[0] = o
        this.postLoginService.getPendingReview().subscribe(
          res=>{
            this.pendingReview=res['data'];
            if(this.pendingReview.booking_id){
              $(document).ready(function(){
                $('#exampleModal').modal('show')
              });
            }
            this.ngxService.stop();
          },
          err=>{
            this.ngxService.stop();
          }
        );
      },
      err=>{
        this.ngxService.stop();
      }
    );


    $(document).ready(function(){
      var x,y,top,left,down;

      $(".slider").mousedown(function(e){
          e.preventDefault();
          down = true;
          x = e.pageX;
          y = e.pageY;
          top = $(this).scrollTop();
          left = $(this).scrollLeft();
      });

      $("body").mousemove(function(e){
          if(down){
              var newX = e.pageX;
              var newY = e.pageY;

              $(".slider").scrollTop(top - newY + y);
              $(".slider").scrollLeft(left - newX + x);
          }
      });
      $('body').on('click','.arrow.leftSide',function(){
        $('#style-9 .drag-scroll-content').animate( { scrollLeft: $('#style-9 .drag-scroll-content').scrollLeft() -350 }, 500, 'easeOutQuad' );
      });
      $('body').on('click','.arrow.rightSide',function(){
        $('#style-9 .drag-scroll-content').animate( { scrollLeft: $('#style-9 .drag-scroll-content').scrollLeft()+350 }, 500, 'easeOutQuad' );
      });
      $("body").mouseup(function(e){down = false;});
      $('body').on('click', '.card1.rotate', function() {
        $('.card1').removeClass('activeCard');
        $(this).addClass('activeCard');
      });
    });
  }

  selectCategory(id,catId,name,url){
    if(id==2){
      this.postLoginService.bookingData.subCategoryId=id;
      this.postLoginService.bookingData.mainCategoryId=catId;
      this.postLoginService.bookingData.workoutName=name;
      this.postLoginService.bookingData.addressLocationId="46";
      this.postLoginService.bookingData.addressAreaId="22";
      this.postLoginService.bookingData.short_address="Online Video Coaching";
      this.postLoginService.bookingData.locationType="GymLocation";
      this.postLoginService.bookingData.areaName="Online Video Coaching";
      this.postLoginService.bookingData.workoutImageURL=url;
      this.router.navigate(['calendar']);
    }
    else{
      this.postLoginService.bookingData.subCategoryId=id;
      this.postLoginService.bookingData.mainCategoryId=catId;
      this.postLoginService.bookingData.workoutName=name;
      this.postLoginService.bookingData.workoutImageURL=url;
      this.router.navigate(['location']);
    }
  }
  selectThumb(op){
    this.enableBtn=true;
    this.selectedThumb=op;
  }
  selectOption(op){
    this.selectedOption[op]=!this.selectedOption[op];
  }

  dismissReview(){
    if(confirm("Are you close without reviewing? You will not be able to provide a review again.")) {
      this.ngxService.start();
      this.postLoginService.dismissReview(this.pendingReview.booking_id).subscribe(
        res=>{
          this.successMessage='Review dismissed';
        setTimeout(() => {
          this.successMessage='';
        }, 3000);
        this.ngxService.stop();
        },
        err=>{
          this.errorMessage=err.error.error;
          ;
          setTimeout(() => {
            this.errorMessage='';
          }, 3000);
          this.ngxService.stop();
        }
    );
  }
  }

  submitReview(){
    this.ngxService.start();
    this.postLoginService.createReview(this.pendingReview.booking_id,this.selectedThumb,this.comment,
      this.selectedOption[0],this.selectedOption[1],this.selectedOption[2],this.selectedOption[3],
      this.selectedOption[4],this.selectedOption[5]).subscribe(
      res=>{
        this.successMessage='Review submitted successfully!';
        setTimeout(() => {
          this.successMessage='';
        }, 3000);
        this.ngxService.stop();
        $(document).ready(function(){
          $('#exampleModal').modal('hide')
        });
      },
      err=>{
        this.errorMessage=err.error.error;
        ;
        setTimeout(() => {
          this.errorMessage='';
        }, 3000);
        this.ngxService.stop();
        $(document).ready(function(){
          $('#exampleModal').modal('hide')
        });
      }
    );

  }
}
