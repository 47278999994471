import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';

@Component({
  selector: 'app-your-locations',
  templateUrl: './your-locations.component.html',
  styleUrls: ['./your-locations.component.css']
})
export class YourLocationsComponent implements OnInit {
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService) {
      this.authGuard.isTokenValid();
    this.ngxService.start();
    this.postLoginService.getUserLocations().subscribe(
      res=>{
        this.locations=res;
        this.postLoginService.getAreaCoordinates().subscribe(
          res=>{
            res.forEach(area => {
              var latLngArray=[]
              area.polygon_coordinates.forEach(path => {
                var latLng = {"lat":path.coordinate[0],"lng":path.coordinate[1]}
                latLngArray.push(latLng);
              });
              this.paths.push({"id":String(area.id),"cords":latLngArray});
              this.ngxService.stop();
            });
          });
      }
    );
    }

  locations;
  paths=[];
  newPlaceAddress;
  errorMessage='';
  successMessage='';
  disableButton=true;
  user_location;

  ngOnInit(): void {

  }

  checkGeoFence(lat,lng){
    var isInside=null;
    this.paths.forEach(path => {
      if(isInside==null){
        var pathPolygon = new google.maps.Polygon({ paths: path.cords });
        var curPosition = new google.maps.LatLng(lat,lng);
        const resultColor = google.maps.geometry.poly.containsLocation(
          curPosition,
          pathPolygon
        )
          ? path.id
          : null;
        if(resultColor!=null){
          isInside=resultColor;
        }
      }
    });
    return isInside;
  }


  public handleAddressChange(address: Address) {
    this.disableButton=true;
    var areaId = this.checkGeoFence(address.geometry.location.lat(),address.geometry.location.lng());
    if(areaId==null)
    {
        this.errorMessage="We are not serving this location. Make sure the location falls under the marked area";
        setTimeout(() => {
          this.newPlaceAddress='';
          this.errorMessage='';
        }, 3000);
    }
    else{
      this.disableButton=false;
      this.user_location = {
        id:address.place_id,
        area_id:address.place_id,
        latitude:Number(address.geometry.location.lat()),
        longitude:Number(address.geometry.location.lng()),
        short_address:address.name,
        full_address:address.formatted_address,
        area:{
          id:'',
          name:''
        }
      }
      this.user_location.area_id=areaId;
    }
  }

  clear(){
    this.newPlaceAddress=undefined;
    this.user_location=undefined;
  }


  saveNewLocation(){
    this.postLoginService.userLocationCreate(this.user_location).subscribe(
      res=>{
        this.successMessage="New address saved successfully!";
        setTimeout(() => {
          this.successMessage='';
        }, 3000);
      },
      err=>{
        this.errorMessage = err.error.error;
        setTimeout(() => {
          this.errorMessage='';
        }, 3000);
      }
    );
  }

}
