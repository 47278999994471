import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { PostloginServiceService } from 'src/app/shared/postlogin-service.service';
declare var $:any;
@Component({
  selector: 'app-coach-profile',
  templateUrl: './coach-profile.component.html',
  styleUrls: ['./coach-profile.component.css']
})
export class CoachProfileComponent implements OnInit {

  constructor(public router:Router,
    public authGuard : AuthGuardService,
    public postLoginService : PostloginServiceService,
    private ngxService: NgxUiLoaderService) {
      this.authGuard.isTokenValid();
      this.ngxService.start();
      this.postLoginService.getTrainerProfile(this.postLoginService.bookingData.trainerId).subscribe(
        res=>{
          this.coach=res['trainer'];
          this.postLoginService.getTrainerPrice(this.postLoginService.bookingData.trainerId,this.postLoginService.bookingData.sessionLength,this.noSessions,this.noParticipants).subscribe(
            res=>{
              this.coachPrice=res['price_quote'];
              this.perSession=Number(this.coachPrice.session_price);
              this.postLoginService.getTrainerTimeSlots(this.postLoginService.bookingData.trainerId,this.postLoginService.bookingData.sessionLength,
                this.noSessions,
                this.postLoginService.bookingData.subCategoryId,this.noParticipants,
                this.postLoginService.bookingData.addressAreaId,this.postLoginService.bookingData.selectedDate,
                this.postLoginService.bookingData.locationType,this.postLoginService.bookingData.addressLocationId,
                this.postLoginService.bookingData.selectedTime,
                this.postLoginService.bookingData.facilityMember).subscribe(
                  res=>{
                    this.timeSlots=res['time_ranges'];
                    if(this.postLoginService.bookingData.selectedTime!="Any Time"){
                      this.timeSlots= this.timeSlots[this.postLoginService.bookingData.selectedTime]
                      this.timeSlots.forEach(timeSlot => {
                        var start = new Date(timeSlot.start_time);
                        var end = new Date(timeSlot.end_time);
                        var formatedTime = this.formatTime(start)+" - "+this.formatTime(end)
                        this.formattedSlots.push(formatedTime);
                      });
                    }
                    else{
                      for (let key in this.timeSlots) {
                        this.timeSlots[key].forEach(timeSlot => {
                        var start = new Date(timeSlot.start_time);
                        var end = new Date(timeSlot.end_time);
                        var formatedTime = this.formatTime(start)+" - "+this.formatTime(end)
                        this.formattedSlots.push(formatedTime);
                        });
                      }
                    }
                    this.ngxService.stop();

                  }
              );
            }
          );

        }
      )
    }
  errorMessage='';
  successMessage='';
  coach;
  coachPrice;
  show=false;
  perSession;
  noSessions=10;
  noParticipants=1;
  timeSlots;
  formattedSlots=[]
  preferredSlot='';
  ngOnInit(): void {
    $( document ).ready(function() {
      $('#carouselExampleIndicators').carousel()
  });
    if(this.postLoginService.bookingData.trainerId==''){
      this.router.navigate(['showCoaches']);
    }
  }
  formatTime(date){
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var hrs = hours<10 ? '0'+hours : hours;
    var min = minutes < 10 ? '0'+minutes : minutes;
    var timeKey = hrs + ':' + min + '' + ampm;
    return timeKey;
  }

  fetchNewPrice(noSessions=1,noParticipants=1){
    this.ngxService.start();
    this.postLoginService.getTrainerPrice(this.postLoginService.bookingData.trainerId,this.postLoginService.bookingData.sessionLength,noSessions,noParticipants).subscribe(
      res=>{
        this.coachPrice=res['price_quote'];
        this.perSession=Number(this.coachPrice.session_price);
        this.ngxService.stop();
      });
  }
  increment(type){
    if(type=="session"){
        if(this.noSessions==1){
          this.noSessions=5;
        }
        else{
          this.noSessions+=5;
        }
        this.fetchNewPrice(this.noSessions,this.noParticipants);
    }else{
      this.noParticipants+=1;
      this.fetchNewPrice(this.noSessions,this.noParticipants);
    }
  }

  decrement(type){
    if(type=="session"){
        if(this.noSessions==5){
          this.noSessions=1;
        }
        else{
          this.noSessions-=5;
        }
      this.fetchNewPrice(this.noSessions,this.noParticipants);
    }else{
        this.noParticipants-=1;
      this.fetchNewPrice(this.noSessions,this.noParticipants);
  }
}
  next(){
    if(this.preferredSlot!=''){
      this.postLoginService.bookingData.noParticipants=Number(this.noParticipants);
    this.postLoginService.bookingData.perSession=Number(this.perSession);
    this.postLoginService.bookingData.noSessions=Number(this.noSessions);
    this.postLoginService.bookingData.trainerName=this.coach.first_name;
    this.postLoginService.bookingData.coachFees=this.coachPrice.session_price;
    this.postLoginService.bookingData.selectedTimeNew=this.preferredSlot;
    this.router.navigate(['bookingSummary']);
  }
  else{
    this.errorMessage='Please select valid time slot.';
    setTimeout(() => {
      this.errorMessage='';
    }, 3000);
  }
  }
}
