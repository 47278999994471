<app-header></app-header>
<div class="fullBGLogin">
  <div class="halfBG" [style.background-image]="'url('+postLoginService.bookingData.workoutImageURL+')'" ></div>
    <div class="container text-center">
        <h1 class="bookingHeading">{{this.postLoginService.bookingData.workoutName}}</h1>
        <div *ngIf="errorMessage!=''" class="alert alert-danger ">
            {{errorMessage}}
          </div>
          <div *ngIf="successMessage!=''" class="alert alert-success ">
              {{successMessage}}
            </div>

        <div class="row  no-gutters  mainBox">
            <div class="col-md-6 pd-t-20 bd-l bd-t bd-b headFont">
                Select date
            </div>
            <div class="col-md-6 pd-t-20 bd headFont">
                select time
            </div>
                <div class="col-md-6 pd-t-20 bd-l bd-t bd-b ht-350">
                    <div class="calendar-inline">
                      <ngb-datepicker (navigate)="monthRefresh()" #dp (dateSelect)="onDateSelected($event)" [markDisabled]="markDisabled" [minDate]="startAt" [maxDate]="endAt"  [(ngModel)]="selectedDate"></ngb-datepicker>
                    </div>
                </div>
                <div class="col-md-6 bd pd-t-20">
                    <div class="mapBox">
                        <div class="rightHeadDiv row">
                            <div class="col-md-6 col-6">
                                <p>Date </p>
                            </div>
                            <div class="col-md-6 col-6 dateText" layout-align='center center' layout='column'>
                                {{selectedDate | date:'dd MMMM, yyyy'}}
                            </div>
                        </div>
                        <div *ngIf="this.postLoginService.bookingData.bookingId==''" class="rightHeadDiv row">
                            <div class="col-md-6 col-6">
                                <p>Preferred time</p>
                            </div>
                            <div class="col-md-6 col-6 timeText">
                                <select [(ngModel)]="selectedTime" class="custom-select darkInputBox">
                                      <option selected value='Any Time'>Any Time</option>
                                      <option value='6:00AM - 10:00AM'>6:00AM - 10:00AM</option>
                                      <option value='10:00AM - 2:00PM'>10:00AM - 2:00PM</option>
                                      <option value='2:00PM - 6:00PM'>2:00PM - 6:00PM</option>
                                      <option value='6:00PM - 10:00PM'>6:00PM - 10:00PM</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="this.postLoginService.bookingData.bookingId!='' && this.isDateSelected!=''" class="rightHeadDiv row">
                          <div class="col-md-6 col-6">
                              <p>Coach Availlability</p>
                          </div>
                          <div class="col-md-6 col-6 timeText">
                              <select (change)="timeSelected($event.target.value)" [(ngModel)]="selectedTime" class="custom-select darkInputBox">
                                <option selected disabled value='Any Time'>Select Time</option>
                                <option *ngFor="let item of this.formattedDates[isDateSelected] | keyvalue" selected [value]='item.key'>{{item.key}}</option>
                              </select>
                          </div>
                        </div>
                        <div *ngIf="this.postLoginService.bookingData.bookingId!='' &&  isTimeSelected!=''" class="rightHeadDiv row">
                          <div class="col-md-6 col-6">
                              <p>Time Slot</p>
                          </div>
                          <div class="col-md-6 col-6 timeText">
                              <select [(ngModel)]="selectedTimeSlot" class="custom-select darkInputBox">
                                <option selected disabled value=''>Select Time Slot</option>
                                <option *ngFor="let item of this.formattedDates[isDateSelected][isTimeSelected] " selected [value]='item[0]'>{{item[0]}}</option>
                              </select>
                          </div>
                        </div>
                        <div class="rightHeadDiv row" *ngIf="this.postLoginService.bookingData.bookingId==''">
                            <div class="col-7 col-md-6">
                              <p class="head">Session length</p>
                            </div>
                            <div class="col-3">
                              {{sessionLength}} min
                            </div>
                            <div class="col-3 counterHead">
                              <button [disabled]="sessionLength==30"  class="btn btn-light btn-icon circleBttn" (click)="decrement()" href="#"><i class="fa fa-minus"></i></button>
                              <button  [disabled]="sessionLength>=120" class="btn btn-light btn-icon circleBttn" (click)="increment()" href="#"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                        <div *ngIf="postLoginService.bookingData.locationType=='GymLocation' && postLoginService.bookingData.short_address!='Online Video Coaching'" class="rightHeadDiv row">
                            <div class="col-7 col-md-8">
                              <p class="head">Facility Member</p>
                            </div>
                            <div class="col-4">
                              <div class="on-off-toggle">
                                <input class="on-off-toggle__input" type="checkbox" id="bopis" (change)="toggleFacilityMember()" />
                                <label for="bopis" class="on-off-toggle__slider"></label>
                              </div>

                            </div>
                        </div>
                        <div class="col-md-12 mgTop">
                            <button *ngIf="this.postLoginService.bookingData.bookingId==''" (click)="next()" type="button" class="btn btn-yellow btn-block">NEXT</button>
                            <button *ngIf="this.postLoginService.bookingData.bookingId!=''"  (click)="nextToBookingSummary()" type="button" class="btn btn-yellow btn-block">NEXT</button>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
