<app-header></app-header>
<div class="fullBGLogin">
  <div class="halfBG" [style.background-image]="'url('+postLoginService.bookingData.workoutImageURL+')'" ></div>
    <div class="container text-center">
        <h1 class="bookingHeading">{{this.postLoginService.bookingData.workoutName}}</h1>
        <div *ngIf="existingBookingError!=''" class="alert alert-danger ">
            {{existingBookingError}}
          </div>
          <div *ngIf="errorMessage!=''" class="alert alert-danger ">
            {{errorMessage}}
          </div>
          <div *ngIf="noDates==true" class="alert alert-danger ">
            No slots available for the selected location. Please choose a new location.
          </div>
          <div *ngIf="successMessage!=''" class="alert alert-success ">
              {{successMessage}}
            </div>
        <div class="row  no-gutters mg-t-20">
                <div class="col-md-6">
                    <div *ngIf="selectedGymId!=''" class="overLayMap">
                        Gym Location Selected<br>{{result.short_address}}
                    </div>
                    <agm-map (mapClick)="mapClick()" [zoom]="11" [latitude]="result.latitude*1" [longitude]="result.longitude*1">
                        <agm-marker (markerClick)="markerClick(iw,loc.id)" *ngFor="let loc of locations"
                        [latitude]="loc.latitude*1"
                        [longitude]="loc.longitude*1"
                        [iconUrl]="loc.latitude==result.latitude &&  loc.longitude==result.longitude ? '/assets/img/yellowMarker.png' : '/assets/img/redMarker.png'">
                            <agm-info-window [isOpen]="loc.latitude==result.latitude &&  loc.longitude==result.longitude ? true : false" #iw>{{loc.short_address}}</agm-info-window>
                        </agm-marker>
                        <agm-polygon [fillOpacity]="0.3"
                        strokeWeight="1"
                        strokeColor="#ef4230"
                        *ngFor="let path of paths" [paths]="path.cords"> </agm-polygon>
                    </agm-map>
                </div>
                <div class="col-md-6">
                    <div class="mapBox">
                        <div class="innerBox pd-t-20">
                            <div class="col-md-10 offset-md-1">
                            <h4>Select Addresses</h4>

                                <div class="form-group">
                                    <select [(ngModel)]="selectedAddress" (change)="onSelectChange($event.target.value)" class="custom-select darkInputBox">
                                      <option  *ngIf="locations?.length==0" disabled selected value="" > No user Location found. Select add new below</option>
                                      <option *ngFor="let item of locations" value="{{item.id}}">{{item.short_address}}</option>
                                      <option value="addNew" >Add new address</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-md-10 offset-md-1" *ngIf="addNewAddress!=''">
                                <h4>New Addresses</h4>
                                <div class="form-group">
                                    <input [(ngModel)]="newPlaceAddress" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"  class="form-control darkInputBox" placeholder="Enter address">
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                        <button *ngIf="enableSaveLoc==true" (click)="clear()" class="btn btn-dark ">CLEAR</button>
                                        <button *ngIf="enableSaveLoc==true" (click)="saveNewLocation()" class="btn btn-yellow float-right">SAVE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="innerBox">
                            <div class="col-md-10 offset-md-1">
                            <h4>Gyms</h4>
                            <div class="scrollbar pd-b-10" id="style-9" style="height:140px">
                                <div class="force-overflow">
                                    <ng-container *ngFor="let gym of gymLocations;let i=index">
                                        <div (click)="selectGymLocation(i,gym.id)" [class.selected]="selectedGym[i]" class="gymBox">
                                            <div class="row">
                                                <div class="col-md-3 col-3">
                                                    <img [src]="gym.avatar" class="avatar">
                                                </div>
                                                <div class="col-md-9 col-9 text-left">
                                                    <h5>{{gym.name}}</h5>
                                                    <p>{{gym.short_address}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div><!--inner box-->
                        <div class="col-md-12 mg-t-20">
                            <button (click)="next()" type="button" class="btn btn-yellow btn-block">NEXT</button>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
